<template>
  <div class="contact-form">
    <template v-if="mailIsSend">
      <p class="contact-form__confirmation-message">
        {{ $t('The message has been sent successfully, we will contact you as soon as possible.') }}
      </p>
    </template>
    <Form v-else class="d-flex flex-column" @submit="onSubmit" novalidate enctype="multipart/form-data">
      <input-text id="name" name="name" :label="$t('Name')" rules="required" />

      <input-text id="email" name="email" :label="$t('E-mail')" rules="required|email" />

      <input-text id="phone" name="phone" :label="$t('Phone number')" />

      <input-textarea id="message" name="message" :label="$t('Message')" rules="required" />

      <button class="contact-form__submit-btn btn btn-primary mt-3" type="submit" @click="formIsSubmitted = true">
        {{ $t('Send message') }}
      </button>
    </Form>
  </div>
</template>

<script>
import { Form } from 'vee-validate';
import { useToast } from 'vue-toastification';
import InputText from './form/input-text';
import InputTextarea from './form/input-textarea';

const toast = useToast();

export default {
  name: 'ContactForm',

  data() {
    return {
      formIsSubmitted: false,
      mailIsSend: false,
    };
  },

  components: {
    Form,
    InputText,
    InputTextarea,
  },

  methods: {
    async onSubmit(values) {
      await this.$store.dispatch('SEND_EMAIL', values);
      this.$emit('mailIsSend');
      this.mailIsSend = true;

      toast.success(this.$t('The message has been sent successfully, we will contact you as soon as possible.'));
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/functions', '~bootstrap/scss/variables', '~bootstrap/scss/mixins';
@import '../styles/variables';
@import '../styles/mixins';

.contact-form {
  &__confirmation-message {
    font-size: pxToRem(18px);
  }

  &__submit-btn {
    align-self: flex-start;
  }
}
</style>
