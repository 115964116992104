import isEqual from 'lodash.isequal';
import {
  getServiceList,
  getService,
  createService,
  editService,
  deleteService,
  getPublicServiceList,
} from '../../services/backend';

export default {
  state: {
    services: null,
    publicServices: null,
    service: null,
    serviceChanges: null,
  },
  getters: {
    SERVICES: (state) => (state.services && state.services.data ? state.services.data : state.services),
    PUBLIC_SERVICES: (state) =>
      state.publicServices && state.publicServices.data ? state.publicServices.data : state.publicServices,
    SERVICE: (state) => ({ ...(state.service || {}), ...state.serviceChanges }),
  },
  mutations: {
    SET_SERVICES: (state, services) => {
      state.services = Object.freeze(services);
    },
    SET_PUBLIC_SERVICES: (state, publicServices) => {
      state.publicServices = Object.freeze(publicServices);
    },
    SET_SERVICE: (state, service) => {
      if (isEqual(service, state.service)) {
        return;
      }

      state.service = service ? { ...service } : null;
      state.serviceChanges = {};
    },
    SET_SERVICE_CHANGES: (state, changes) => {
      state.serviceChanges = changes;
    },
    CLEAR_SERVICE: (state) => {
      state.service = null;
      state.serviceChanges = null;
    },
  },
  actions: {
    async LOAD_SERVICES({ commit }) {
      const { data } = await getServiceList();

      commit('SET_SERVICES', data);
    },
    async LOAD_PUBLIC_SERVICES({ commit }) {
      const { data } = await getPublicServiceList();

      commit('SET_PUBLIC_SERVICES', data);
    },
    async CREATE_SERVICE(context, service) {
      const { data } = await createService(service);

      return data;
    },
    async EDIT_SERVICE(context, service) {
      const { data } = await editService(service);

      return data;
    },
    async LOAD_SERVICE({ commit }, serviceId) {
      const { data } = await getService(serviceId);

      commit('CLEAR_SERVICE');
      commit('SET_SERVICE', data);

      return data;
    },
    async DELETE_SERVICE(context, serviceId) {
      const { data } = await deleteService(serviceId);

      return data;
    },
    CLEAR_SESSION_STATE({ commit }) {
      commit('CLEAR_SERVICE', null);
    },
  },
};
