<template>
  <div class="top-bar">
    <nav class="top-bar__menu nav">
      <router-link
        v-for="page in filteredTopMenuPages"
        :key="page.id"
        class="top-bar__menu-link nav-link"
        :to="
          page.full_url === 'contact-nl' || page.full_url === 'contact-en'
            ? `/${currentLanguage}/contact`
            : `/${currentLanguage}/${page.full_url}` || '/'
        "
        exact-path
        >{{ page.menu_title }}</router-link
      >
      <localized-link v-if="secureApiUser" class="top-bar__menu-link nav-link" :to="{ name: 'logout' }" exact>{{
        $t('Logout')
      }}</localized-link>
    </nav>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import sortBy from 'lodash.sortby';

export default {
  name: 'TopBar',
  components: {},
  data() {
    return {};
  },
  props: {},
  computed: {
    ...mapGetters({
      dutchPages: 'PUBLIC_DUTCH_PAGES',
      englishPages: 'PUBLIC_ENGLISH_PAGES',
      secureApiUser: 'SECURE_API_USER',
    }),
    currentLanguage() {
      return this.$route.path.includes('/en/') || this.$route.fullPath === '/en' ? 'en' : 'nl';
    },
    pages() {
      return this.currentLanguage === 'en' ? this.englishPages : this.dutchPages;
    },
    filteredTopMenuPages() {
      return this.pages && this.pages.length > 0
        ? sortBy(
            this.pages.filter((page) => page.visible_in_top_menu),
            'position_in_top_menu'
          )
        : [];
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/functions', '~bootstrap/scss/variables', '~bootstrap/scss/mixins';
@import '../styles/variables';
@import '../styles/mixins';

.top-bar {
  background: $tiber;
  display: flex;
  justify-content: flex-end;
  padding-right: pxToRem(80px);

  @include media-breakpoint-up(sm) {
    padding-right: pxToRem(135px);
  }

  @include media-breakpoint-up(lg) {
    padding-right: pxToRem(165px);
  }

  &__menu {
    padding: 0;
    position: relative;
    z-index: 1000;

    .nav-item {
      @include media-breakpoint-up(lg) {
        align-items: center;
        display: flex;
        text-align: center;
      }
    }
  }

  &__menu-link {
    color: $white;
    font-size: pxToRem(15px);
    opacity: 1;
    padding: pxToRem(5px) pxToRem(15px);

    &:hover,
    &.active {
      color: $white;
    }

    &:hover {
      opacity: 0.9;
      text-decoration: underline;
    }

    &.active {
      opacity: 1;
      text-decoration: underline;
    }
  }
}
</style>
