import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import Cookies from 'js-cookie';
// import { storeApiPlugin } from '../services/api';
import progressApi from './modules/progress-api';
import user from './modules/user';
import time from './modules/time';
import modal from './modules/modal';
import page from './modules/page';
import feature from './modules/feature';
import service from './modules/service';
import news from './modules/news';
import tip from './modules/tip';
import review from './modules/review';
import customer from './modules/customer';
import email from './modules/email';
import emailPhone from './modules/email-phone';
import emailRemoteAssistance from './modules/email-remote-assistance';
import product from './modules/product';
import zzpProduct from './modules/zzp-product';
import accountantDownload from './modules/accountant-download';
import statusNewPc from './modules/status-new-pc';
import statusRepair from './modules/status-repair';
import newsGroup from './modules/news-group';
import newsCategory from './modules/news-category';
import serviceGroup from './modules/service-group';

export default createStore({
  // Don't enable strict for production
  // see: https://vuex.vuejs.org/guide/strict.html
  strict: process.env.NODE_ENV === 'development',
  state: {},
  mutations: {},
  actions: {},
  modules: {
    progressApi,
    user,
    time,
    modal,
    page,
    feature,
    service,
    news,
    tip,
    review,
    customer,
    email,
    emailPhone,
    emailRemoteAssistance,
    product,
    zzpProduct,
    accountantDownload,
    statusNewPc,
    statusRepair,
    newsGroup,
    newsCategory,
    serviceGroup,
  },
  plugins: [
    // Here we register outr plugin that will watch the
    // authHeader state and set out authHeaders accordingly
    // storeApiPlugin,
    createPersistedState({
      paths: ['user'],
      storage: {
        getItem: (key) => Cookies.get(key),
        setItem: (key, value) => Cookies.set(key, value, { expires: 3, secure: false }),
        removeItem: (key) => Cookies.remove(key),
      },
    }),
  ],
});
