const without = (arr, ...args) => arr.filter((item) => !args.includes(item));

export default {
  state: {
    modals: [],
  },
  mutations: {
    ADD_MODAL: (state, modal) => {
      state.modals.push(Object.freeze(modal));
    },
    REMOVE_MODAL: (state, modal) => {
      state.modals = without(state.modals, modal);
    },
  },
  getters: {
    MODALS: (state) => state.modals,
  },
  actions: {
    OPEN_MODAL: ({ commit }, { component, props }) =>
      new Promise((resolve) => {
        let r = resolve;
        const modal = {
          component,
          props,
          resolve: (result) => {
            if (result === 'close-modal') {
              r(null);
            } else {
              r(result);
            }

            // unset resolve to make sure only the first call resolves the promise
            r = () => {};
            commit('REMOVE_MODAL', modal);
          },
          remove: () => {
            commit('REMOVE_MODAL', modal);
          },
        };

        commit('ADD_MODAL', modal);
      }),
  },
};
