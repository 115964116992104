import isEqual from 'lodash.isequal';
import {
  getStatusRepairList,
  getStatusRepair,
  createStatusRepair,
  editStatusRepair,
  deleteStatusRepair,
  getNextStatusRepairId,
  getStatusRepairOfCustomer,
} from '../../services/backend';

export default {
  state: {
    statusesRepair: null,
    statusRepair: null,
    statusRepairChanges: null,
    nextStatusRepairId: null,
    currentStatusRepair: null,
  },
  getters: {
    STATUSES_REPAIR: (state) =>
      state.statusesRepair && state.statusesRepair.data ? state.statusesRepair.data : state.statusesRepair,
    STATUS_REPAIR: (state) => ({ ...(state.statusRepair || {}), ...state.statusRepairChanges }),
    NEXT_STATUS_REPAIR_ID: (state) => state.nextStatusRepairId,
    CURRENT_STATUS_REPAIR: (state) => state.currentStatusRepair,
  },
  mutations: {
    SET_STATUSES_REPAIR: (state, statusesRepair) => {
      state.statusesRepair = Object.freeze(statusesRepair);
    },
    SET_STATUS_REPAIR: (state, statusRepair) => {
      if (isEqual(statusRepair, state.statusRepair)) {
        return;
      }

      state.statusRepair = statusRepair ? { ...statusRepair } : null;
      state.statusRepairChanges = {};
    },
    SET_STATUS_REPAIR_CHANGES: (state, changes) => {
      state.statusRepairChanges = changes;
    },
    SET_CURRENT_STATUS_REPAIR: (state, statusRepair) => {
      if (isEqual(statusRepair, state.currentStatusRepair)) {
        return;
      }

      state.currentStatusRepair = statusRepair ? { ...statusRepair } : null;
    },
    CLEAR_STATUS_REPAIR: (state) => {
      state.statusRepair = null;
      state.statusRepairChanges = null;
    },
    CLEAR_CURRENT_STATUS_REPAIR: (state) => {
      state.currentStatusRepair = null;
    },
    SET_NEXT_STATUS_REPAIR_ID: (state, nextStatusRepairId) => {
      state.nextStatusRepairId = nextStatusRepairId;
    },
  },
  actions: {
    async LOAD_STATUSES_REPAIR({ commit }) {
      const { data } = await getStatusRepairList();

      commit('SET_STATUSES_REPAIR', data);
    },
    async CREATE_STATUS_REPAIR(context, statusRepair) {
      const { data } = await createStatusRepair(statusRepair);

      return data;
    },
    async EDIT_STATUS_REPAIR(context, statusRepair) {
      const { data } = await editStatusRepair(statusRepair);

      return data;
    },
    async LOAD_STATUS_REPAIR({ commit }, statusRepairId) {
      const { data } = await getStatusRepair(statusRepairId);

      commit('CLEAR_STATUS_REPAIR');
      commit('SET_STATUS_REPAIR', data);

      return data;
    },
    async DELETE_STATUS_REPAIR(context, statusRepairId) {
      const { data } = await deleteStatusRepair(statusRepairId);

      return data;
    },
    async LOAD_NEXT_STATUS_REPAIR_ID({ commit }) {
      const { data } = await getNextStatusRepairId();

      commit('SET_NEXT_STATUS_REPAIR_ID', data);
    },
    CLEAR_SESSION_STATE({ commit }) {
      commit('CLEAR_STATUS_REPAIR', null);
    },
    async LOAD_STATUS_REPAIR_OF_CUSTOMER({ commit }) {
      const { data } = await getStatusRepairOfCustomer();

      commit('CLEAR_CURRENT_STATUS_REPAIR');
      commit('SET_CURRENT_STATUS_REPAIR', data);

      return data;
    },
  },
};
