import isEqual from 'lodash.isequal';
import {
  getProductList,
  getProduct,
  createProduct,
  editProduct,
  deleteProduct,
  getNextProductId,
  getPublicProductsList,
  getPublicProductsCount,
  doesPublicProductExist,
  getPublicProductByUrl,
  doesProductTitleExist,
} from '../../services/backend';

export default {
  state: {
    products: null,
    publicProducts: null,
    publicProductsCount: null,
    product: null,
    productChanges: null,
    nextProductId: null,
    currentPublicProduct: null,
  },
  getters: {
    PRODUCTS: (state) => (state.products && state.products.data ? state.products.data : state.products),
    PUBLIC_PRODUCTS: (state) =>
      state.publicProducts && state.publicProducts.data ? state.publicProducts.data : state.publicProducts,
    PUBLIC_PRODUCTS_COUNT: (state) => state.publicProductsCount,
    PRODUCT: (state) => ({ ...(state.product || {}), ...state.productChanges }),
    CURRENT_PUBLIC_PRODUCT: (state) => state.currentPublicProduct,
    NEXT_PRODUCT_ID: (state) => state.nextProductId,
  },
  mutations: {
    SET_PRODUCTS: (state, products) => {
      state.products = Object.freeze(products);
    },
    SET_PUBLIC_PRODUCTS: (state, publicProducts) => {
      state.publicProducts = Object.freeze(publicProducts);
    },
    SET_PRODUCT: (state, product) => {
      if (isEqual(product, state.product)) {
        return;
      }

      state.product = product ? { ...product } : null;
      state.productChanges = {};
    },
    SET_CURRENT_PUBLIC_PRODUCT: (state, product) => {
      if (isEqual(product, state.currentPublicProduct)) {
        return;
      }

      state.currentPublicProduct = product ? { ...product } : null;
    },
    SET_PRODUCT_CHANGES: (state, changes) => {
      state.productChanges = changes;
    },
    CLEAR_PRODUCT: (state) => {
      state.product = null;
      state.productChanges = null;
    },
    SET_NEXT_PRODUCT_ID: (state, nextProductId) => {
      state.nextProductId = nextProductId;
    },
    SET_PUBLIC_PRODUCTS_COUNT: (state, publicProductsCount) => {
      state.publicProductsCount = publicProductsCount;
    },
    CLEAR_CURRENT_PUBLIC_PRODUCT: (state) => {
      state.currentPublicProduct = null;
    },
  },
  actions: {
    async LOAD_PRODUCTS({ commit }) {
      const { data } = await getProductList();

      commit('SET_PRODUCTS', data);
    },
    async LOAD_PUBLIC_PRODUCTS({ commit }, { pageNumber, language }) {
      const { data } = await getPublicProductsList(pageNumber, language);

      commit('SET_PUBLIC_PRODUCTS', data);
    },
    async CREATE_PRODUCT(context, product) {
      const { data } = await createProduct(product);

      return data;
    },
    async EDIT_PRODUCT(context, product) {
      const { data } = await editProduct(product);

      return data;
    },
    async LOAD_PRODUCT({ commit }, productId) {
      const { data } = await getProduct(productId);

      commit('CLEAR_PRODUCT');
      commit('SET_PRODUCT', data);

      return data;
    },
    async DELETE_PRODUCT(context, productId) {
      const { data } = await deleteProduct(productId);

      return data;
    },
    async LOAD_NEXT_PRODUCT_ID({ commit }) {
      const { data } = await getNextProductId();

      commit('SET_NEXT_PRODUCT_ID', data);
    },
    CLEAR_SESSION_STATE({ commit }) {
      commit('CLEAR_PRODUCT', null);
    },
    async LOAD_PUBLIC_PRODUCTS_COUNT({ commit }, language) {
      const { data } = await getPublicProductsCount(language);

      commit('SET_PUBLIC_PRODUCTS_COUNT', data);
    },
    async DOES_PUBLIC_PRODUCT_EXIST(context, url) {
      const { data } = await doesPublicProductExist(url);

      return data;
    },
    async DOES_PRODUCT_TITLE_EXIST(context, title) {
      const { data } = await doesProductTitleExist(title);

      return data;
    },
    async LOAD_PUBLIC_PRODUCT_BY_URL({ commit }, url) {
      const { data } = await getPublicProductByUrl(url);

      commit('CLEAR_CURRENT_PUBLIC_PRODUCT');
      commit('SET_CURRENT_PUBLIC_PRODUCT', data);

      return data;
    },
    async CLEAR_CURRENT_PUBLIC_PRODUCT({ commit }) {
      commit('CLEAR_CURRENT_PUBLIC_PRODUCT', null);
    },
  },
};
