<template>
  <div id="modals">
    <component
      v-for="(modal, index) in modals"
      :is="modal.component"
      @resolve="modal.resolve"
      @hide="modal.resolve()"
      @hidden="modal.remove"
      :key="index"
      v-bind="modal.props"
    />
  </div>
</template>

<script>
export default {
  name: 'ModalList',
  computed: {
    modals() {
      return this.$store.getters.MODALS;
    },
  },
};
</script>

<style lang="scss">
@import '../../styles/variables';

// fix for vue-bootstrap not adding the "show" class to the backdrop
.modal-backdrop {
  opacity: $modal-backdrop-opacity;
}
</style>
