import isEqual from 'lodash.isequal';
import {
  getAccountantDownload,
  editAccountantDownload,
  getAccountantDownloadsOfAccountant,
} from '../../services/backend';

export default {
  state: {
    accountantDownload: null,
    accountantDownloadChanges: null,
    currentAccountantDownload: null,
  },
  getters: {
    ACCOUNTANT_DOWNLOAD: (state) => ({ ...(state.accountantDownload || {}), ...state.accountantDownloadChanges }),
    CURRENT_ACCOUNTANT_DOWNLOAD: (state) => state.currentAccountantDownload,
  },
  mutations: {
    SET_ACCOUNTANT_DOWNLOAD: (state, accountantDownload) => {
      if (isEqual(accountantDownload, state.accountantDownload)) {
        return;
      }

      state.accountantDownload = accountantDownload ? { ...accountantDownload } : null;
      state.accountantDownloadChanges = {};
    },
    SET_ACCOUNTANT_DOWNLOAD_CHANGES: (state, changes) => {
      state.accountantDownloadChanges = changes;
    },
    SET_CURRENT_ACCOUNTANT_DOWNLOAD: (state, accountantDownload) => {
      if (isEqual(accountantDownload, state.currentAccountantDownload)) {
        return;
      }

      state.currentAccountantDownload = accountantDownload ? { ...accountantDownload } : null;
    },
    CLEAR_ACCOUNTANT_DOWNLOAD: (state) => {
      state.accountantDownload = null;
      state.accountantDownloadChanges = null;
    },
    CLEAR_CURRENT_ACCOUNTANT_DOWNLOAD: (state) => {
      state.currentAccountantDownload = null;
    },
  },
  actions: {
    async EDIT_ACCOUNTANT_DOWNLOAD(context, accountantDownload) {
      const { data } = await editAccountantDownload(accountantDownload);

      return data;
    },
    async LOAD_ACCOUNTANT_DOWNLOAD({ commit }, accountantDownloadId) {
      const { data } = await getAccountantDownload(accountantDownloadId);

      commit('CLEAR_ACCOUNTANT_DOWNLOAD');
      commit('SET_ACCOUNTANT_DOWNLOAD', data);

      return data;
    },
    async LOAD_ACCOUNTANT_DOWNLOAD_OF_ACCOUNTANT({ commit }) {
      const { data } = await getAccountantDownloadsOfAccountant();

      commit('CLEAR_CURRENT_ACCOUNTANT_DOWNLOAD');
      commit('SET_CURRENT_ACCOUNTANT_DOWNLOAD', data);

      return data;
    },
    CLEAR_SESSION_STATE({ commit }) {
      commit('CLEAR_ACCOUNTANT_DOWNLOAD', null);
    },
  },
};
