<template>
  <svg
    :class="{ 'waves-border': true, 'waves-border--flipped-horizontally': flipHorizontally }"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 24 150 28 "
    preserveAspectRatio="none"
  >
    <defs>
      <path id="wave-path" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"></path>
    </defs>
    <g class="wave wave-1">
      <use xlink:href="#wave-path" x="50" y="3" fill="rgba(255,255,255, .1)"></use>
    </g>
    <g class="wave wave-2">
      <use xlink:href="#wave-path" x="50" y="0" fill="rgba(255,255,255, .2)"></use>
    </g>
    <g class="wave wave-3">
      <use xlink:href="#wave-path" x="50" y="9" fill="#fff"></use>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'Waves',
  props: {
    flipHorizontally: { type: Boolean, default: false },
  },
};
</script>

<style lang="scss" scoped>
@import '../styles/mixins';

@keyframes wave-animation-1 {
  0% {
    transform: translate(85px, 0%);
  }
  100% {
    transform: translate(-90px, 0%);
  }
}

@keyframes wave-animation-2 {
  0% {
    transform: translate(-90px, 0%);
  }
  100% {
    transform: translate(85px, 0%);
  }
}

.waves-border {
  display: block;
  width: 100%;
  height: pxToRem(50px);
  position: relative;

  &--flipped-horizontally {
    transform: scaleY(-1);
  }

  .wave {
    use {
      animation-delay: -2s;
    }
  }

  .wave-1 {
    use {
      animation: wave-animation-1 20s linear infinite;
    }
  }

  .wave-2 {
    use {
      animation: wave-animation-2 16s linear infinite;
    }
  }

  .wave-3 {
    use {
      animation: wave-animation-2 12s linear infinite;
    }
  }
}
</style>
