import { createI18n } from 'vue-i18n';
import { vueI18nMessages } from './lang/translations';

const i18n = createI18n({
  locale: 'nl',
  fallbackLocale: 'nl',
  messages: vueI18nMessages,
});

export default i18n;
