import isEqual from 'lodash.isequal';
import {
  getStatusNewPcList,
  getStatusNewPc,
  createStatusNewPc,
  editStatusNewPc,
  deleteStatusNewPc,
  getNextStatusNewPcId,
  getStatusNewPcOfCustomer,
} from '../../services/backend';

export default {
  state: {
    statusesNewPc: null,
    statusNewPc: null,
    statusNewPcChanges: null,
    nextStatusNewPcId: null,
    currentStatusNewPc: null,
  },
  getters: {
    STATUSES_NEW_PC: (state) =>
      state.statusesNewPc && state.statusesNewPc.data ? state.statusesNewPc.data : state.statusesNewPc,
    STATUS_NEW_PC: (state) => ({ ...(state.statusNewPc || {}), ...state.statusNewPcChanges }),
    NEXT_STATUS_NEW_PC_ID: (state) => state.nextStatusNewPcId,
    CURRENT_STATUS_NEW_PC: (state) => state.currentStatusNewPc,
  },
  mutations: {
    SET_STATUSES_NEW_PC: (state, statusesNewPc) => {
      state.statusesNewPc = Object.freeze(statusesNewPc);
    },
    SET_STATUS_NEW_PC: (state, statusNewPc) => {
      if (isEqual(statusNewPc, state.statusNewPc)) {
        return;
      }

      state.statusNewPc = statusNewPc ? { ...statusNewPc } : null;
      state.statusNewPcChanges = {};
    },
    SET_STATUS_NEW_PC_CHANGES: (state, changes) => {
      state.statusNewPcChanges = changes;
    },
    SET_CURRENT_STATUS_NEW_PC: (state, statusNewPc) => {
      if (isEqual(statusNewPc, state.currentStatusNewPc)) {
        return;
      }

      state.currentStatusNewPc = statusNewPc ? { ...statusNewPc } : null;
    },
    CLEAR_STATUS_NEW_PC: (state) => {
      state.statusNewPc = null;
      state.statusNewPcChanges = null;
    },
    CLEAR_CURRENT_STATUS_NEW_PC: (state) => {
      state.currentStatusNewPc = null;
    },
    SET_NEXT_STATUS_NEW_PC_ID: (state, nextStatusNewPcId) => {
      state.nextStatusNewPcId = nextStatusNewPcId;
    },
  },
  actions: {
    async LOAD_STATUSES_NEW_PC({ commit }) {
      const { data } = await getStatusNewPcList();

      commit('SET_STATUSES_NEW_PC', data);
    },
    async CREATE_STATUS_NEW_PC(context, statusNewPc) {
      const { data } = await createStatusNewPc(statusNewPc);

      return data;
    },
    async EDIT_STATUS_NEW_PC(context, statusNewPc) {
      const { data } = await editStatusNewPc(statusNewPc);

      return data;
    },
    async LOAD_STATUS_NEW_PC({ commit }, statusNewPcId) {
      const { data } = await getStatusNewPc(statusNewPcId);

      commit('CLEAR_STATUS_NEW_PC');
      commit('SET_STATUS_NEW_PC', data);

      return data;
    },
    async DELETE_STATUS_NEW_PC(context, statusNewPcId) {
      const { data } = await deleteStatusNewPc(statusNewPcId);

      return data;
    },
    async LOAD_NEXT_STATUS_NEW_PC_ID({ commit }) {
      const { data } = await getNextStatusNewPcId();

      commit('SET_NEXT_STATUS_NEW_PC_ID', data);
    },
    CLEAR_SESSION_STATE({ commit }) {
      commit('CLEAR_STATUS_NEW_PC', null);
    },
    async LOAD_STATUS_NEW_PC_OF_CUSTOMER({ commit }) {
      const { data } = await getStatusNewPcOfCustomer();

      commit('CLEAR_CURRENT_STATUS_NEW_PC');
      commit('SET_CURRENT_STATUS_NEW_PC', data);

      return data;
    },
  },
};
