import isEqual from 'lodash.isequal';
import {
  getTipList,
  getTip,
  createTip,
  editTip,
  deleteTip,
  getNextTipId,
  getPublicTipsList,
  getPublicTipsCount,
  doesPublicTipExist,
  getPublicTipByUrl,
  getPublicTipById,
  doesTipTitleExist,
  getRandomTips,
  getAllPublicTips,
} from '../../services/backend';

export default {
  state: {
    tips: null,
    publicTips: null,
    publicTipsCount: null,
    tip: null,
    tipChanges: null,
    nextTipId: null,
    currentPublicTip: null,
    randomTips: null,
    allPublicTips: null,
  },
  getters: {
    TIPS: (state) => (state.tips && state.tips.data ? state.tips.data : state.tips),
    PUBLIC_TIPS: (state) => (state.publicTips && state.publicTips.data ? state.publicTips.data : state.publicTips),
    PUBLIC_TIPS_COUNT: (state) => state.publicTipsCount,
    TIP: (state) => ({ ...(state.tip || {}), ...state.tipChanges }),
    CURRENT_PUBLIC_TIP: (state) => state.currentPublicTip,
    NEXT_TIP_ID: (state) => state.nextTipId,
    RANDOM_TIPS: (state) => (state.randomTips && state.randomTips.data ? state.randomTips.data : state.randomTips),
    ALL_PUBLIC_TIPS: (state) =>
      state.allPublicTips && state.allPublicTips.data ? state.allPublicTips.data : state.allPublicTips,
  },
  mutations: {
    SET_TIPS: (state, tips) => {
      state.tips = Object.freeze(tips);
    },
    SET_PUBLIC_TIPS: (state, publicTips) => {
      state.publicTips = Object.freeze(publicTips);
    },
    SET_TIP: (state, tip) => {
      if (isEqual(tip, state.tip)) {
        return;
      }

      state.tip = tip ? { ...tip } : null;
      state.tipChanges = {};
    },
    SET_CURRENT_PUBLIC_TIP: (state, tip) => {
      if (isEqual(tip, state.currentPublicTip)) {
        return;
      }

      state.currentPublicTip = tip ? { ...tip } : null;
    },
    SET_TIP_CHANGES: (state, changes) => {
      state.tipChanges = changes;
    },
    CLEAR_TIP: (state) => {
      state.tip = null;
      state.tipChanges = null;
    },
    SET_NEXT_TIP_ID: (state, nextTipId) => {
      state.nextTipId = nextTipId;
    },
    SET_PUBLIC_TIPS_COUNT: (state, publicTipsCount) => {
      state.publicTipsCount = publicTipsCount;
    },
    CLEAR_CURRENT_PUBLIC_TIP: (state) => {
      state.currentPublicTip = null;
    },
    SET_RANDOM_TIPS: (state, randomTips) => {
      state.randomTips = Object.freeze(randomTips);
    },
    SET_ALL_PUBLIC_TIPS: (state, allPublicTips) => {
      state.allPublicTips = Object.freeze(allPublicTips);
    },
  },
  actions: {
    async LOAD_TIPS({ commit }) {
      const { data } = await getTipList();

      commit('SET_TIPS', data);
    },
    async LOAD_PUBLIC_TIPS({ commit }, { pageNumber, language }) {
      const { data } = await getPublicTipsList(pageNumber, language);

      commit('SET_PUBLIC_TIPS', data);
    },
    async CREATE_TIP(context, tip) {
      const { data } = await createTip(tip);

      return data;
    },
    async EDIT_TIP(context, tip) {
      const { data } = await editTip(tip);

      return data;
    },
    async LOAD_TIP({ commit }, tipId) {
      const { data } = await getTip(tipId);

      commit('CLEAR_TIP');
      commit('SET_TIP', data);

      return data;
    },
    async DELETE_TIP(context, tipId) {
      const { data } = await deleteTip(tipId);

      return data;
    },
    async LOAD_NEXT_TIP_ID({ commit }) {
      const { data } = await getNextTipId();

      commit('SET_NEXT_TIP_ID', data);
    },
    async LOAD_PUBLIC_TIPS_COUNT({ commit }, language) {
      const { data } = await getPublicTipsCount(language);

      commit('SET_PUBLIC_TIPS_COUNT', data);
    },
    CLEAR_SESSION_STATE({ commit }) {
      commit('CLEAR_TIP', null);
    },
    async DOES_PUBLIC_TIP_EXIST(context, url) {
      const { data } = await doesPublicTipExist(url);

      return data;
    },
    async DOES_TIP_TITLE_EXIST(context, title) {
      const { data } = await doesTipTitleExist(title);

      return data;
    },
    async LOAD_PUBLIC_TIP_BY_URL({ commit }, url) {
      const { data } = await getPublicTipByUrl(url);

      commit('CLEAR_CURRENT_PUBLIC_TIP');
      commit('SET_CURRENT_PUBLIC_TIP', data);

      return data;
    },
    async LOAD_PUBLIC_TIP_BY_ID(context, id) {
      const { data } = await getPublicTipById(id);
      return data;
    },
    async CLEAR_CURRENT_PUBLIC_TIP({ commit }) {
      commit('CLEAR_CURRENT_PUBLIC_TIP', null);
    },
    async LOAD_RANDOM_TIPS({ commit }, language) {
      const { data } = await getRandomTips(language);

      commit('SET_RANDOM_TIPS', data);
    },
    async LOAD_ALL_PUBLIC_TIPS({ commit }, language) {
      const { data } = await getAllPublicTips(language);

      commit('SET_ALL_PUBLIC_TIPS', data);
    },
  },
};
