import { defineRule, configure } from 'vee-validate';
import { required, email, min_value } from '@vee-validate/rules';
import { localize } from '@vee-validate/i18n';

// defineRule('required', (value) => {
//   if (!value || !value.length) {
//     return "Het is verplicht om het veld '{_field_}' in te vullen.";
//   }
//   return true;
// });

defineRule('required', required);

defineRule('email', email);

defineRule('min_value', min_value);

configure({
  generateMessage: localize({
    en: {
      messages: {
        required: `The '{field}' field is required.`,
      },
    },
    nl: {
      messages: {
        required: `Het is verplicht om het veld '{field}' in te vullen.`,
      },
    },
  }),
});
