import isEqual from 'lodash.isequal';
import { getCustomerList, getCustomer, createCustomer, deleteCustomer } from '../../services/backend';

export default {
  state: {
    customers: null,
    customer: null,
  },
  getters: {
    CUSTOMERS: (state) => (state.customers && state.customers.data ? state.customers.data : state.customers),
    CUSTOMER: (state) => state.customer || {},
  },
  mutations: {
    SET_CUSTOMERS: (state, customers) => {
      state.customers = Object.freeze(customers);
    },
    SET_CUSTOMER: (state, customer) => {
      if (isEqual(customer, state.customer)) {
        return;
      }

      state.customer = customer ? { ...customer } : null;
    },
    CLEAR_CUSTOMER: (state) => {
      state.customer = null;
    },
  },
  actions: {
    async LOAD_CUSTOMERS({ commit }) {
      const { data } = await getCustomerList();

      commit('SET_CUSTOMERS', data);
    },
    async CREATE_CUSTOMER(context, customer) {
      const { data } = await createCustomer(customer);

      return data;
    },
    async LOAD_CUSTOMER({ commit }, customerId) {
      const { data } = await getCustomer(customerId);

      commit('CLEAR_CUSTOMER');
      commit('SET_CUSTOMER', data);

      return data;
    },
    async DELETE_CUSTOMER(context, customerId) {
      const { data } = await deleteCustomer(customerId);

      return data;
    },
    CLEAR_SESSION_STATE({ commit }) {
      commit('CLEAR_CUSTOMER', null);
    },
  },
};
