import isEqual from 'lodash.isequal';
import {
  getNewsList,
  getNews,
  createNews,
  editNews,
  deleteNews,
  getNextNewsId,
  getPublicNewsList,
  getPublicNewsCount,
  doesPublicNewsItemExist,
  getPublicNewsItemByUrl,
  getPublicNewsById,
  doesNewsItemTitleExist,
  getRandomNews,
  getPublicNewsPastYear,
  getPublicNewsByFieldValue,
  getSpecificNewsItems,
} from '../../services/backend';

export default {
  state: {
    news: null,
    publicNews: null,
    publicNewsCount: null,
    newsItem: null,
    newsChanges: null,
    nextNewsId: null,
    currentPublicNewsItem: null,
    randomNews: null,
    publicNewsPastYear: null,
    publicNewsByCategory: {},
    publicNewsByGroup: {},
    specificNewsItems: {},
  },
  getters: {
    NEWS: (state) => (state.news && state.news.data ? state.news.data : state.news),
    PUBLIC_NEWS: (state) => (state.publicNews && state.publicNews.data ? state.publicNews.data : state.publicNews),
    PUBLIC_NEWS_COUNT: (state) => state.publicNewsCount,
    NEWS_ITEM: (state) => ({ ...(state.newsItem || {}), ...state.newsChanges }),
    CURRENT_PUBLIC_NEWS_ITEM: (state) => state.currentPublicNewsItem,
    NEXT_NEWS_ID: (state) => state.nextNewsId,
    RANDOM_NEWS: (state) => (state.randomNews && state.randomNews.data ? state.randomNews.data : state.randomNews),
    PUBLIC_NEWS_PAST_YEAR: (state) =>
      state.publicNewsPastYear && state.publicNewsPastYear.data
        ? state.publicNewsPastYear.data
        : state.publicNewsPastYear,
    PUBLIC_NEWS_BY_CATEGORY: (state) => state.publicNewsByCategory,
    PUBLIC_NEWS_BY_GROUP: (state) => state.publicNewsByGroup,
    SPECIFIC_NEWS_ITEMS: (state) => state.specificNewsItems,
  },
  mutations: {
    SET_NEWS: (state, news) => {
      state.news = Object.freeze(news);
    },
    SET_PUBLIC_NEWS: (state, publicNews) => {
      state.publicNews = Object.freeze(publicNews);
    },
    SET_NEWS_ITEM: (state, newsItem) => {
      if (isEqual(newsItem, state.newsItem)) {
        return;
      }

      state.newsItem = newsItem ? { ...newsItem } : null;
      state.newsChanges = {};
    },
    SET_CURRENT_PUBLIC_NEWS_ITEM: (state, newsItem) => {
      if (isEqual(newsItem, state.currentPublicNewsItem)) {
        return;
      }

      state.currentPublicNewsItem = newsItem ? { ...newsItem } : null;
    },
    SET_NEWS_CHANGES: (state, changes) => {
      state.newsChanges = changes;
    },
    CLEAR_NEWS_ITEM: (state) => {
      state.newsItem = null;
      state.newsChanges = null;
    },
    SET_NEXT_NEWS_ID: (state, nextNewsId) => {
      state.nextNewsId = nextNewsId;
    },
    SET_PUBLIC_NEWS_COUNT: (state, publicNewsCount) => {
      state.publicNewsCount = publicNewsCount;
    },
    CLEAR_CURRENT_PUBLIC_NEWS_ITEM: (state) => {
      state.currentPublicNewsItem = null;
    },
    SET_RANDOM_NEWS: (state, randomNews) => {
      state.randomNews = Object.freeze(randomNews);
    },
    SET_PUBLIC_NEWS_PAST_YEAR: (state, publicNewsPastYear) => {
      state.publicNewsPastYear = Object.freeze(publicNewsPastYear);
    },
    SET_PUBLIC_NEWS_CATEGORY: (state, { category, data }) => {
      state.publicNewsByCategory = { ...state.publicNewsByCategory, [category]: data.data };
    },
    SET_PUBLIC_NEWS_GROUP: (state, { group, data }) => {
      state.publicNewsByGroup = { ...state.publicNewsByGroup, [group]: data.data };
    },
    SET_SPECIFIC_NEWS_ITEMS: (state, { id, data }) => {
      state.specificNewsItems = { ...state.specificNewsItems, [id]: data.data };
    },
  },
  actions: {
    async LOAD_NEWS({ commit }) {
      const { data } = await getNewsList();

      commit('SET_NEWS', data);
    },
    async LOAD_PUBLIC_NEWS({ commit }, { pageNumber, language }) {
      const { data } = await getPublicNewsList(pageNumber, language);

      commit('SET_PUBLIC_NEWS', data);
    },
    async CREATE_NEWS_ITEM(context, newsItem) {
      const { data } = await createNews(newsItem);

      return data;
    },
    async EDIT_NEWS_ITEM(context, newsItem) {
      const { data } = await editNews(newsItem);

      return data;
    },
    async LOAD_NEWS_ITEM({ commit }, newsId) {
      const { data } = await getNews(newsId);

      commit('CLEAR_NEWS_ITEM');
      commit('SET_NEWS_ITEM', data);

      return data;
    },
    async DELETE_NEWS_ITEM(context, newsId) {
      const { data } = await deleteNews(newsId);

      return data;
    },
    async LOAD_NEXT_NEWS_ID({ commit }) {
      const { data } = await getNextNewsId();

      commit('SET_NEXT_NEWS_ID', data);
    },
    async LOAD_PUBLIC_NEWS_COUNT({ commit }, language) {
      const { data } = await getPublicNewsCount(language);

      commit('SET_PUBLIC_NEWS_COUNT', data);
    },
    CLEAR_SESSION_STATE({ commit }) {
      commit('CLEAR_NEWS_ITEM', null);
    },
    async DOES_PUBLIC_NEWS_ITEM_EXIST(context, url) {
      const { data } = await doesPublicNewsItemExist(url);

      return data;
    },
    async DOES_NEWS_ITEM_TITLE_EXIST(context, title) {
      const { data } = await doesNewsItemTitleExist(title);

      return data;
    },
    async LOAD_PUBLIC_NEWS_ITEM_BY_URL({ commit }, url) {
      const { data } = await getPublicNewsItemByUrl(url);

      commit('CLEAR_CURRENT_PUBLIC_NEWS_ITEM');
      commit('SET_CURRENT_PUBLIC_NEWS_ITEM', data);

      return data;
    },
    async LOAD_PUBLIC_NEWS_BY_ID(context, id) {
      const { data } = await getPublicNewsById(id);
      return data;
    },
    async CLEAR_CURRENT_PUBLIC_NEWS_ITEM({ commit }) {
      commit('CLEAR_CURRENT_PUBLIC_NEWS_ITEM', null);
    },
    async LOAD_RANDOM_NEWS({ commit }, language) {
      const { data } = await getRandomNews(language);

      commit('SET_RANDOM_NEWS', data);
    },
    async LOAD_PUBLIC_NEWS_PAST_YEAR({ commit }, language) {
      const { data } = await getPublicNewsPastYear(language);

      commit('SET_PUBLIC_NEWS_PAST_YEAR', data);
    },
    async LOAD_PUBLIC_NEWS_BY_CATEGORY({ commit }, { language, category }) {
      const { data } = await getPublicNewsByFieldValue(language, 'category', category);

      commit('SET_PUBLIC_NEWS_CATEGORY', { category, data });
    },
    async LOAD_PUBLIC_NEWS_BY_GROUP({ commit }, { language, group }) {
      const { data } = await getPublicNewsByFieldValue(language, 'news_group', group);

      commit('SET_PUBLIC_NEWS_GROUP', { group, data });
    },
    async LOAD_SPECIFIC_NEWS_ITEMS({ commit }, { language, id }) {
      const { data } = await getSpecificNewsItems(language, id);

      commit('SET_SPECIFIC_NEWS_ITEMS', { id, data });
    },
  },
};
