import isEqual from 'lodash.isequal';
import {
  getReviewList,
  getReview,
  createReview,
  editReview,
  deleteReview,
  getPublicReviewList,
} from '../../services/backend';

export default {
  state: {
    reviews: null,
    publicReviews: null,
    review: null,
    reviewChanges: null,
  },
  getters: {
    REVIEWS: (state) => (state.reviews && state.reviews.data ? state.reviews.data : state.reviews),
    PUBLIC_REVIEWS: (state) =>
      state.publicReviews && state.publicReviews.data ? state.publicReviews.data : state.publicReviews,
    REVIEW: (state) => ({ ...(state.review || {}), ...state.reviewChanges }),
  },
  mutations: {
    SET_REVIEWS: (state, reviews) => {
      state.reviews = Object.freeze(reviews);
    },
    SET_PUBLIC_REVIEWS: (state, publicReviews) => {
      state.publicReviews = Object.freeze(publicReviews);
    },
    SET_REVIEW: (state, review) => {
      if (isEqual(review, state.review)) {
        return;
      }

      state.review = review ? { ...review } : null;
      state.reviewChanges = {};
    },
    SET_REVIEW_CHANGES: (state, changes) => {
      state.reviewChanges = changes;
    },
    CLEAR_REVIEW: (state) => {
      state.review = null;
      state.reviewChanges = null;
    },
  },
  actions: {
    async LOAD_REVIEWS({ commit }) {
      const { data } = await getReviewList();

      commit('SET_REVIEWS', data);
    },
    async LOAD_PUBLIC_REVIEWS({ commit }) {
      const { data } = await getPublicReviewList();

      commit('SET_PUBLIC_REVIEWS', data);
    },
    async CREATE_REVIEW(context, review) {
      const { data } = await createReview(review);

      return data;
    },
    async EDIT_REVIEW(context, review) {
      const { data } = await editReview(review);

      return data;
    },
    async LOAD_REVIEW({ commit }, reviewId) {
      const { data } = await getReview(reviewId);

      commit('CLEAR_REVIEW');
      commit('SET_REVIEW', data);

      return data;
    },
    async DELETE_REVIEW(context, reviewId) {
      const { data } = await deleteReview(reviewId);

      return data;
    },
    CLEAR_SESSION_STATE({ commit }) {
      commit('CLEAR_REVIEW', null);
    },
  },
};
