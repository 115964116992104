<template>
  <div class="contact-info">
    <p>
      Profs Computers<br />
      Straat van Malakka 6<br />
      2622 KM Delft
    </p>

    <p>
      <a href="mailto:info@profscomputers.nl">info@profscomputers.nl</a><br />
      <a href="tel:0158795335">015 879 53 35</a><br />
      <a href="tel:0613454693">06 13 45 46 93</a>
    </p>

    <p>
      KVK 55357881<br />
      BTW Nr. NL001711413B83
    </p>
  </div>
</template>

<script>
export default {
  name: 'ContactInfo',
};
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/functions', '~bootstrap/scss/variables', '~bootstrap/scss/mixins';
@import '../styles/variables';
@import '../styles/mixins';

.contact-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;

  @include media-breakpoint-up(sm) {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    text-align: left;
  }

  p {
    @include media-breakpoint-up(sm) {
      margin-right: pxToRem(30px);
    }
  }

  a {
    color: rgba(255, 255, 255, 0.9);
    text-decoration: none;

    &:hover {
      color: $white;
      text-decoration: underline;
    }
  }
}
</style>
