import isEqual from 'lodash.isequal';
import { getEmailList, getEmail, createEmail, deleteEmail, sendEmail } from '../../services/backend';

export default {
  state: {
    emails: null,
    email: null,
  },
  getters: {
    EMAILS: (state) => (state.emails && state.emails.data ? state.emails.data : state.emails),
    EMAIL: (state) => state.email || {},
  },
  mutations: {
    SET_EMAILS: (state, emails) => {
      state.emails = Object.freeze(emails);
    },
    SET_EMAIL: (state, email) => {
      if (isEqual(email, state.email)) {
        return;
      }

      state.email = email ? { ...email } : null;
    },
    CLEAR_EMAIL: (state) => {
      state.email = null;
    },
  },
  actions: {
    async LOAD_EMAILS({ commit }) {
      const { data } = await getEmailList();

      commit('SET_EMAILS', data);
    },
    async CREATE_EMAIL(context, email) {
      const { data } = await createEmail(email);

      return data;
    },
    async LOAD_EMAIL({ commit }, emailId) {
      const { data } = await getEmail(emailId);

      commit('CLEAR_EMAIL');
      commit('SET_EMAIL', data);

      return data;
    },
    async DELETE_EMAIL(context, emailId) {
      const { data } = await deleteEmail(emailId);

      return data;
    },
    CLEAR_SESSION_STATE({ commit }) {
      commit('CLEAR_EMAIL', null);
    },
    async SEND_EMAIL(context, email) {
      const { data } = await sendEmail(email);

      return data;
    },
  },
};
