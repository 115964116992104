<template>
  <div
    class="language-switch"
    :class="{
      'language-switch--admin-menu-visible': cmsAdminMenuIsVisible(),
      scrolled: classObject.scrolled,
      awake: classObject.awake,
    }"
  >
    <div
      class="language-switch__button"
      :class="{ open: languageSwitchIsOpen }"
      @click="languageSwitchIsOpen = languageSwitchIsOpen ? false : true"
      :title="currentLanguage === 'en' ? 'English' : 'Nederlands'"
    >
      <span class="language-switch__current-language">{{ currentLanguage === 'en' ? 'English' : 'Nederlands' }}</span>
      <span class="language-switch__current-language--mobile">{{ currentLanguage === 'en' ? 'EN' : 'NL' }}</span>
    </div>
    <div
      class="language-switch__menu"
      :class="{ open: languageSwitchIsOpen }"
      role="navigation"
      v-click-outside="onClickOutside"
    >
      <template
        v-if="
          currentRouteName !== 'page' &&
          currentRouteName !== 'subPage' &&
          currentRouteName !== 'newsOverview' &&
          currentRouteName !== 'newsDetail' &&
          currentRouteName !== 'tipOverview' &&
          currentRouteName !== 'tipDetail' &&
          currentRouteName !== 'contactPage'
        "
      >
        <language-switcher class="language-switch__list" v-slot="{ links }" tag="ul" active-class="active">
          <li class="language-switch__list-item" :class="link.activeClass" v-for="link in links" :key="link.langIndex">
            <a class="language-switch__link" :href="link.url">{{
              link.langIndex === 'en' ? 'English' : 'Nederlands'
            }}</a>
          </li>
        </language-switcher>
      </template>
      <ul v-else class="language-switch__list">
        <li class="language-switch__list-item">
          <a class="language-switch__link" :href="englishTranslationUrl">{{ 'English' }}</a>
        </li>
        <li class="language-switch__list-item">
          <a class="language-switch__link" :href="dutchTranslationUrl">{{ 'Nederlands' }}</a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { setLocale } from '@vee-validate/i18n';
import vClickOutside from 'click-outside-vue3';
import WithPageInfo from './with-page-info';

export default {
  name: 'LanguageSwitch',
  mixins: [WithPageInfo],
  data() {
    return {
      classObject: {
        scrolled: false,
        awake: false,
      },
      languageSwitchIsOpen: false,
    };
  },
  props: {
    englishTranslationUrl: { type: String, required: true },
    dutchTranslationUrl: { type: String, required: true },
  },
  computed: {
    currentLanguage() {
      return this.$route.path.includes('/en/') || this.$route.fullPath === '/en' ? 'en' : 'nl';
    },
    currentRouteName() {
      return this.$route.name;
    },
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  mounted() {
    const currentLanguage = this.$route.path.includes('/en/') || this.$route.fullPath === '/en' ? 'en' : 'nl';

    window.addEventListener('scroll', this.handleScroll);
    setLocale(currentLanguage);
    this.$root.$i18n.locale = currentLanguage;
  },
  unmounted() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    onClickOutside(event) {
      if (
        event.target.classList &&
        !event.target.classList.contains('language-switch__button') &&
        !event.target.classList.contains('language-switch__current-language') &&
        !event.target.classList.contains('language-switch__current-language--mobile')
      ) {
        this.languageSwitchIsOpen = false;
      }
    },
    handleScroll() {
      const scrollTop = window.scrollY;

      if (scrollTop > 150) {
        this.classObject.scrolled = true;
      }
      if (scrollTop < 150) {
        this.classObject.scrolled = false;
      }
      if (scrollTop > 350) {
        this.classObject.awake = true;
      }
      if (scrollTop < 350) {
        this.classObject.awake = false;
      }
    },
  },
  watch: {
    $route(to, from) {
      const languageDestinationPath = to.path.includes('/en/') ? 'en' : 'nl';
      const languagePreviousPath = from.path.includes('/en/') ? 'en' : 'nl';

      if (languageDestinationPath !== languagePreviousPath) {
        setLocale(languageDestinationPath);
        this.$root.$i18n.locale = languageDestinationPath;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/functions', '~bootstrap/scss/variables', '~bootstrap/scss/mixins';
@import '../styles/variables';
@import '../styles/mixins';
@import '../styles/fonts';

.language-switch {
  align-items: center;
  display: flex;
  min-height: pxToRem(37px);
  position: absolute;
  right: pxToRem(15px);
  top: 0;
  width: pxToRem(65px);
  z-index: 1000;

  @include media-breakpoint-up(sm) {
    width: pxToRem(120px);
  }

  @include media-breakpoint-up(lg) {
    right: pxToRem(45px);
  }

  &.scrolled {
    display: block;
    position: fixed;
    top: pxToRem(-230px);
    z-index: 1000;
  }

  &.awake {
    top: 0;
    transition: 0.3s all ease-out;
  }

  &--admin-menu-visible {
    top: $admin-menu-height;

    &.awake {
      top: $admin-menu-height;
    }
  }

  &__button {
    @extend %trailing-iconfont;
    @extend %trailing-icon-chevron-down;
    color: $white;
    cursor: pointer;
    font-size: pxToRem(15px);
    line-height: pxToRem(37px);
    position: absolute;
    width: pxToRem(65px);

    @include media-breakpoint-up(sm) {
      width: pxToRem(120px);
    }

    &::before {
      content: '|';
      padding-right: pxToRem(15px);
    }

    &::after {
      line-height: pxToRem(39px);
      position: absolute;
      right: 0;
    }

    &.open {
      @extend %trailing-icon-chevron-up;
    }
  }

  &__current-language {
    display: none;

    @include media-breakpoint-up(sm) {
      display: inline-block;
    }

    &--mobile {
      display: inline-block;

      @include media-breakpoint-up(sm) {
        display: none;
      }
    }
  }

  @keyframes open-language-switch {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  &__menu {
    animation: open-language-switch 0.3s ease-out;
    background-color: $venice-blue;
    background: linear-gradient(180deg, $tiber 0%, $cerulean 100%);
    color: $white;
    display: none;
    opacity: 1;
    overflow: hidden;
    padding: pxToRem(7px) 0 pxToRem(12px);
    position: absolute;
    right: 0;
    top: pxToRem(37px);
    z-index: 99;

    &.open {
      display: block;
      margin-right: pxToRem(-15px);
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  &__list-item {
    display: inline-flex;
  }

  &__link {
    color: $white;
    font-size: pxToRem(15px);
    opacity: 1;
    padding: pxToRem(7px) pxToRem(30px);
    width: 100%;

    &:hover {
      opacity: 0.9;
      text-decoration: underline;
    }

    &.active {
      opacity: 1;
      text-decoration: underline;
    }
  }
}
</style>
