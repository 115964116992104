export default {
  state: {
    loadingImages: false,
    runningApiCalls: 0,
  },
  getters: {
    LOADING_IMAGES: (state) => state.loadingImages,
    LOADING: (state) => state.runningApiCalls > 0,
  },
  mutations: {
    INCREMENT_RUNNING_API_CALLS: (state, by = 1) => {
      state.runningApiCalls += by;
    },
    SET_LOADING_IMAGES: (state, loadingImages) => {
      if(state.loadingImages === loadingImages) {
        return;
      }
      state.loadingImages = loadingImages;
    },
  },
  actions: {
    ADD_API_CALL: ({ commit }, call) => {
      commit('INCREMENT_RUNNING_API_CALLS');

      return call
        .then(() => {
          commit('INCREMENT_RUNNING_API_CALLS', -1);
        })
        .catch(() => {
          commit('INCREMENT_RUNNING_API_CALLS', -1);
        });
    },
    START_IMAGE_LOADING({ commit }) {
      commit('SET_LOADING_IMAGES', true);
    },
    STOP_IMAGE_LOADING({ commit }) {
      commit('SET_LOADING_IMAGES', false);
    },
  },
};
