import api from './api';

// Login
export const login = ({ email, password }) =>
  api.post('login', {
    email,
    password,
  });
export const logout = (role) => api.post('logout', { role });

// Page
export const getPageList = () => api.get('pages');
export const getNextPageId = () => api.get('pages/next-page-id');
export const createPage = (page) => api.post('pages/add-page', page);
export const deletePage = (pageId) => api.delete(`page/delete/${pageId}`);
export const getPage = (pageId) => api.get(`page/${pageId}`);
export const editPage = (page) => api.put(`page/edit/${page.id}`, page);
export const getPublicPageList = (language) => api.get(`public-pages/${language}`);
export const doesPageMenuTitleExist = (menuTitle) => api.get(`page-menu-title-exists/${menuTitle}`);
export const pageIsDeleted = () => api.get('/page-is-deleted');

// Features
export const getFeatureList = () => api.get('features');
export const getFeature = (featureId) => api.get(`feature/${featureId}`);
export const createFeature = (feature) => api.post('features/add-feature', feature);
export const editFeature = (feature) => api.put(`feature/edit/${feature.id}`, feature);
export const deleteFeature = (featureId) => api.delete(`feature/delete/${featureId}`);
export const getPublicFeatureList = () => api.get('public-features');

// Services
export const getServiceList = () => api.get('services');
export const getService = (serviceId) => api.get(`service/${serviceId}`);
export const createService = (service) => api.post('services/add-service', service);
export const editService = (service) => api.put(`service/edit/${service.id}`, service);
export const deleteService = (serviceId) => api.delete(`service/delete/${serviceId}`);
export const getPublicServiceList = () => api.get('public-services');

// News
export const getNewsList = () => api.get('news');
export const getNextNewsId = () => api.get('news-item/next-news-id');
export const getNews = (newsId) => api.get(`news/${newsId}`);
export const createNews = (news) => api.post('news/add-news', news);
export const editNews = (news) => api.put(`news/edit/${news.id}`, news);
export const deleteNews = (newsId) => api.delete(`news/delete/${newsId}`);
export const getPublicNewsList = (pageNumber, language) => api.get(`public-news/${language}/${pageNumber}`);
export const getPublicNewsCount = (language) => api.get(`public-news-count/${language}`);
export const doesPublicNewsItemExist = (url) => api.get(`public-news-item-exists/${url}`);
export const getPublicNewsItemByUrl = (url) => api.get(`public-news-item-by-url/${url}`);
export const getPublicNewsById = (id) => api.get(`public-news-by-id/${id}`);
export const doesNewsItemTitleExist = (title) => api.get(`news-item-title-exists/${title}`);
export const getRandomNews = (language) => api.get(`random-news/${language}`);
export const getPublicNewsPastYear = (language) => api.get(`public-news-past-year/${language}`);
export const getPublicNewsByFieldValue = (language, fieldName, fieldValue) =>
  api.get(`public-news-by-field-value/${language}/${fieldName}/${fieldValue}`);
export const getSpecificNewsItems = (language, id) => api.get(`specific-news-items/${language}/${id}`);

// Tips
export const getTipList = () => api.get('tips');
export const getNextTipId = () => api.get('tip/next-tip-id');
export const getTip = (tipId) => api.get(`tip/${tipId}`);
export const createTip = (tip) => api.post('tip/add-tip', tip);
export const editTip = (tip) => api.put(`tip/edit/${tip.id}`, tip);
export const deleteTip = (tipId) => api.delete(`tip/delete/${tipId}`);
export const getPublicTipsList = (pageNumber, language) => api.get(`public-tips/${language}/${pageNumber}`);
export const getPublicTipsCount = (language) => api.get(`public-tips-count/${language}`);
export const doesPublicTipExist = (url) => api.get(`public-tip-exists/${url}`);
export const getPublicTipByUrl = (url) => api.get(`public-tip-by-url/${url}`);
export const getPublicTipById = (id) => api.get(`public-tip-by-id/${id}`);
export const doesTipTitleExist = (title) => api.get(`tip-title-exists/${title}`);
export const getRandomTips = (language) => api.get(`random-tips/${language}`);
export const getAllPublicTips = (language) => api.get(`public-tips-all/${language}`);

// Reviews
export const getReviewList = () => api.get('reviews');
export const getReview = (reviewId) => api.get(`review/${reviewId}`);
export const createReview = (review) => api.post('reviews/add-review', review);
export const editReview = (review) => api.put(`review/edit/${review.id}`, review);
export const deleteReview = (reviewId) => api.delete(`review/delete/${reviewId}`);
export const getPublicReviewList = () => api.get('public-reviews');

// Products
export const getProductList = () => api.get('products');
export const getNextProductId = () => api.get('product/next-product-id');
export const getProduct = (productId) => api.get(`product/${productId}`);
export const createProduct = (product) => api.post('product/add-product', product);
export const editProduct = (product) => api.put(`product/edit/${product.id}`, product);
export const deleteProduct = (productId) => api.delete(`product/delete/${productId}`);
export const getPublicProductsList = (pageNumber, language) => api.get(`public-products/${language}/${pageNumber}`);
export const getPublicProductsCount = (language) => api.get(`public-products-count/${language}`);
export const doesPublicProductExist = (url) => api.get(`public-product-exists/${url}`);
export const getPublicProductByUrl = (url) => api.get(`public-product-by-url/${url}`);
export const doesProductTitleExist = (title) => api.get(`product-title-exists/${title}`);

// Zzp Products
export const getZzpProductList = () => api.get('zzp-products');
export const getNextZzpProductId = () => api.get('zzp-product/next-product-id');
export const getZzpProduct = (zzpProductId) => api.get(`zzp-product/${zzpProductId}`);
export const createZzpProduct = (zzpProduct) => api.post('zzp-product/add-product', zzpProduct);
export const editZzpProduct = (zzpProduct) => api.put(`zzp-product/edit/${zzpProduct.id}`, zzpProduct);
export const deleteZzpProduct = (zzpProductId) => api.delete(`zzp-product/delete/${zzpProductId}`);

// Accountant downnloads
export const getAccountantDownload = (accountantDownloadId) => api.get(`accountant-download/${accountantDownloadId}`);
export const editAccountantDownload = (accountantDownload) =>
  api.put(`accountant-download/edit/${accountantDownload.id}`, accountantDownload);
export const getAccountantDownloadsOfAccountant = () => api.get('accountant-download-of-accountant');

// Statuses new pc
export const getStatusNewPcList = () => api.get('statuses-new-pc');
export const getNextStatusNewPcId = () => api.get('status-new-pc/next-status-new-pc-id');
export const getStatusNewPc = (statusNewPcId) => api.get(`status-new-pc/${statusNewPcId}`);
export const createStatusNewPc = (statusNewPc) => api.post('status-new-pc/add-status-new-pc', statusNewPc);
export const editStatusNewPc = (statusNewPc) => api.put(`status-new-pc/edit/${statusNewPc.id}`, statusNewPc);
export const deleteStatusNewPc = (statusNewPcId) => api.delete(`status-new-pc/delete/${statusNewPcId}`);
export const getStatusNewPcOfCustomer = () => api.get('status-new-pc');

// Statuses repair
export const getStatusRepairList = () => api.get('statuses-repair');
export const getNextStatusRepairId = () => api.get('status-repair/next-status-repair-id');
export const getStatusRepair = (statusRepairId) => api.get(`status-repair/${statusRepairId}`);
export const createStatusRepair = (statusRepair) => api.post('status-repair/add-status-repair', statusRepair);
export const editStatusRepair = (statusRepair) => api.put(`status-repair/edit/${statusRepair.id}`, statusRepair);
export const deleteStatusRepair = (statusRepairId) => api.delete(`status-repair/delete/${statusRepairId}`);
export const getStatusRepairOfCustomer = () => api.get('status-repair');

// Customers
export const getCustomerList = () => api.get('customers');
export const getCustomer = (customerId) => api.get(`customer/${customerId}`);
export const createCustomer = (customer) => api.post('register', customer);
export const deleteCustomer = (customerId) => api.delete(`customer/delete/${customerId}`);

// News groups
export const getNewsGroupList = () => api.get('news-groups');
export const getNewsGroup = (newsGroupId) => api.get(`news-group/${newsGroupId}`);
export const createNewsGroup = (newsGroup) => api.post('news-group/add-news-group', newsGroup);
export const editNewsGroup = (newsGroup) => api.put(`news-group/edit/${newsGroup.id}`, newsGroup);
export const deleteNewsGroup = (newsGroupId) => api.delete(`news-group/delete/${newsGroupId}`);

// News categories
export const getNewsCategoryList = () => api.get('news-categories');
export const getNewsCategory = (newsCategoryId) => api.get(`news-category/${newsCategoryId}`);
export const createNewsCategory = (newsCategory) => api.post('news-category/add-news-category', newsCategory);
export const editNewsCategory = (newsCategory) => api.put(`news-category/edit/${newsCategory.id}`, newsCategory);
export const deleteNewsCategory = (newsCategoryId) => api.delete(`news-category/delete/${newsCategoryId}`);

// Service groups
export const getServiceGroupList = () => api.get('service-groups');
export const getServiceGroup = (serviceGroupId) => api.get(`service-group/${serviceGroupId}`);
export const createServiceGroup = (serviceGroup) => api.post('service-group/add-service-group', serviceGroup);
export const editServiceGroup = (serviceGroup) => api.put(`service-group/edit/${serviceGroup.id}`, serviceGroup);
export const deleteServiceGroup = (serviceGroupId) => api.delete(`service-group/delete/${serviceGroupId}`);

// E-mails
export const getEmailList = () => api.get('emails');
export const getEmail = (emailId) => api.get(`email/${emailId}`);
export const createEmail = (email) => api.post('emails/add-email', email);
export const deleteEmail = (emailId) => api.delete(`email/delete/${emailId}`);
export const getEmailPhoneList = () => api.get('emails-phone');
export const getEmailPhone = (emailId) => api.get(`email-phone/${emailId}`);
export const createEmailPhone = (email) => api.post('emails-phone/add-email', email);
export const deleteEmailPhone = (emailId) => api.delete(`email-phone/delete/${emailId}`);

export const getEmailRemoteAssistanceList = () => api.get('emails-remote-assistance');
export const getEmailRemoteAssistance = (emailId) => api.get(`email-remote-assistance/${emailId}`);
export const createEmailRemoteAssistance = (email) => api.post('emails-remote-assistance/add-email', email);
export const deleteEmailRemoteAssistance = (emailId) => api.delete(`email-remote-assistance/delete/${emailId}`);
export const sendEmail = (email) => api.post('send-contact-email', email);
export const sendPhoneEmail = (email) => api.post('send-phone-email', email);
export const sendRemoteAssistanceEmail = (email) => api.post('send-remote-assistance-email', email);

export const isServerError = ({ response }) =>
  response && (response.status > 500 || response.status === 403 || response.status === 401);
