import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      secureApiUser: 'SECURE_API_USER',
    }),
  },
  methods: {
    cmsAdminMenuIsVisible() {
      return this.secureApiUser && this.secureApiUser.user.role === 'cms-admin';
    },
    getCmsPageHeaderInfo() {
      const currentRouteName = this.$route.name;
      let headerInfo = {};

      if (currentRouteName === 'login-cms-admin') {
        headerInfo = {
          title: this.$t('headerTitleLoginCmsAdmin'),
          intro: this.$t('headerIntroLoginCmsAdmin'),
        };
      } else if (currentRouteName === 'login-cms-accountant') {
        headerInfo = {
          title: this.$t('headerTitleLoginCmsAccountant'),
          intro: this.$t('headerIntroLoginCmsAccountant'),
        };
      } else if (currentRouteName === 'login-cms-status-user') {
        headerInfo = {
          title: this.$t('headerTitleLoginCmsStatus'),
          intro: this.$t('headerIntroLoginCmsStatus'),
        };
      } else if (currentRouteName === 'login-cms-zzp') {
        headerInfo = {
          title: this.$t('headerTitleLoginCmsZzp'),
          intro: this.$t('headerIntroLoginCmsZzp'),
        };
      } else if (currentRouteName === 'dashboard') {
        headerInfo = {
          title: this.$t('Pages'),
        };
      } else if (currentRouteName === 'pages') {
        headerInfo = {
          title: this.$t('Pages'),
        };
      } else if (currentRouteName === 'addPage') {
        headerInfo = {
          title: this.$t('Add page'),
        };
      } else if (currentRouteName === 'editPage') {
        headerInfo = {
          title: this.$t('Edit page'),
        };
      } else if (currentRouteName === 'features') {
        headerInfo = {
          title: this.$t('Features'),
        };
      } else if (currentRouteName === 'addFeature') {
        headerInfo = {
          title: this.$t('Add feature'),
        };
      } else if (currentRouteName === 'editFeature') {
        headerInfo = {
          title: this.$t('Edit feature'),
        };
      } else if (currentRouteName === 'services') {
        headerInfo = {
          title: this.$t('Services'),
        };
      } else if (currentRouteName === 'addService') {
        headerInfo = {
          title: this.$t('Add service'),
        };
      } else if (currentRouteName === 'editService') {
        headerInfo = {
          title: this.$t('Edit service'),
        };
      } else if (currentRouteName === 'news') {
        headerInfo = {
          title: this.$t('News'),
        };
      } else if (currentRouteName === 'addNews') {
        headerInfo = {
          title: this.$t('Add news'),
        };
      } else if (currentRouteName === 'editNews') {
        headerInfo = {
          title: this.$t('Edit news'),
        };
      } else if (currentRouteName === 'tips') {
        headerInfo = {
          title: this.$t('Tips'),
        };
      } else if (currentRouteName === 'addTip') {
        headerInfo = {
          title: this.$t('Add tip'),
        };
      } else if (currentRouteName === 'editTip') {
        headerInfo = {
          title: this.$t('Edit tip'),
        };
      } else if (currentRouteName === 'reviews') {
        headerInfo = {
          title: this.$t('Reviews'),
        };
      } else if (currentRouteName === 'addReview') {
        headerInfo = {
          title: this.$t('Add review'),
        };
      } else if (currentRouteName === 'editReview') {
        headerInfo = {
          title: this.$t('Edit review'),
        };
      } else if (currentRouteName === 'customers') {
        headerInfo = {
          title: this.$t('Customers'),
        };
      } else if (currentRouteName === 'addCustomer') {
        headerInfo = {
          title: this.$t('Add customer'),
        };
      } else if (currentRouteName === 'emails') {
        headerInfo = {
          title: this.$t('E-mails'),
        };
      } else if (currentRouteName === 'viewEmail') {
        headerInfo = {
          title: this.$t('View e-mail'),
        };
      } else if (currentRouteName === 'emailsPhone') {
        headerInfo = {
          title: this.$t('E-mails phone form'),
        };
      } else if (currentRouteName === 'viewEmailPhone') {
        headerInfo = {
          title: this.$t('View e-mail phone form'),
        };
      } else if (currentRouteName === 'products') {
        headerInfo = {
          title: this.$t('Products'),
        };
      } else if (currentRouteName === 'addProduct') {
        headerInfo = {
          title: this.$t('Add product'),
        };
      } else if (currentRouteName === 'editProduct') {
        headerInfo = {
          title: this.$t('Edit product'),
        };
      } else if (currentRouteName === 'zzpProducts') {
        headerInfo = {
          title: this.$t('Zzp products'),
        };
      } else if (currentRouteName === 'addZzpProduct') {
        headerInfo = {
          title: this.$t('Add zzp product'),
        };
      } else if (currentRouteName === 'editZzpProduct') {
        headerInfo = {
          title: this.$t('Edit zzp product'),
        };
      } else if (currentRouteName === 'editAccountantDownload') {
        headerInfo = {
          title: this.$t('Edit accountant downloads'),
        };
      } else if (currentRouteName === 'statusesNewPc') {
        headerInfo = {
          title: this.$t('Statuses new pc'),
        };
      } else if (currentRouteName === 'addStatusNewPc') {
        headerInfo = {
          title: this.$t('Add status new pc'),
        };
      } else if (currentRouteName === 'editStatusNewPc') {
        headerInfo = {
          title: this.$t('Edit status new pc'),
        };
      } else if (currentRouteName === 'statusesRepair') {
        headerInfo = {
          title: this.$t('Statuses repair'),
        };
      } else if (currentRouteName === 'addStatusRepair') {
        headerInfo = {
          title: this.$t('Add status repair'),
        };
      } else if (currentRouteName === 'editStatusRepair') {
        headerInfo = {
          title: this.$t('Edit status repair'),
        };
      } else if (currentRouteName === 'newsGroups') {
        headerInfo = {
          title: this.$t('News groups'),
        };
      } else if (currentRouteName === 'addNewsGroup') {
        headerInfo = {
          title: this.$t('Add news group'),
        };
      } else if (currentRouteName === 'editNewsGroup') {
        headerInfo = {
          title: this.$t('Edit news group'),
        };
      } else if (currentRouteName === 'newsCategories') {
        headerInfo = {
          title: this.$t('News categories'),
        };
      } else if (currentRouteName === 'addNewsCategory') {
        headerInfo = {
          title: this.$t('Add news category'),
        };
      } else if (currentRouteName === 'editNewsCategory') {
        headerInfo = {
          title: this.$t('Edit news category'),
        };
      } else if (currentRouteName === 'serviceGroups') {
        headerInfo = {
          title: this.$t('Service groups'),
        };
      } else if (currentRouteName === 'addServiceGroup') {
        headerInfo = {
          title: this.$t('Add service group'),
        };
      } else if (currentRouteName === 'editServiceGroup') {
        headerInfo = {
          title: this.$t('Edit service group'),
        };
      } else if (currentRouteName === 'status') {
        headerInfo = {
          title: this.$t('Current status new pc and/or repair'),
          subtitle: this.$t('Status'),
        };
      } else if (currentRouteName === 'accountant') {
        headerInfo = {
          title: this.$t('You can download administration documents here'),
          subtitle: this.$t('Download'),
        };
      } else if (currentRouteName === 'pageNotFound') {
        headerInfo = {
          title: this.$t('Page not found'),
          subtitle: this.$t('Oops!'),
          intro: this.$t(`Sorry, we can't find this page anymore`),
          show_action_btn: false,
          show_slider: false,
        };
      } else if (currentRouteName && currentRouteName.includes('pageIsDeleted')) {
        headerInfo = {
          title: this.$t('Page is deleted'),
          subtitle: this.$t('Oops!'),
          intro: this.$t(`Sorry, we can't find this page anymore`),
          show_action_btn: false,
          show_slider: false,
        };
      } else {
        headerInfo = {
          news_category: null,
          title: '',
          subtitle: '',
          intro: '',
          show_action_btn: false,
          action_btn_title: '',
          image: '',
          show_slider: false,
          slider_images: [],
        };
      }

      return headerInfo;
    },
  },
};
