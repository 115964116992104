import isEqual from 'lodash.isequal';
import {
  getZzpProductList,
  getZzpProduct,
  createZzpProduct,
  editZzpProduct,
  deleteZzpProduct,
  getNextZzpProductId,
} from '../../services/backend';

export default {
  state: {
    zzpProducts: null,
    zzpProduct: null,
    zzpProductChanges: null,
    nextzzpProductId: null,
  },
  getters: {
    ZZP_PRODUCTS: (state) => (state.zzpProducts && state.zzpProducts.data ? state.zzpProducts.data : state.zzpProducts),
    ZZP_PRODUCT: (state) => ({ ...(state.zzpProduct || {}), ...state.zzpProductChanges }),
    NEXT_ZZP_PRODUCT_ID: (state) => state.nextZzpProductId,
  },
  mutations: {
    SET_ZZP_PRODUCTS: (state, zzpProducts) => {
      state.zzpProducts = Object.freeze(zzpProducts);
    },
    SET_ZZP_PRODUCT: (state, zzpProduct) => {
      if (isEqual(zzpProduct, state.zzpProduct)) {
        return;
      }

      state.zzpProduct = zzpProduct ? { ...zzpProduct } : null;
      state.zzpProductChanges = {};
    },
    SET_ZZP_PRODUCT_CHANGES: (state, changes) => {
      state.zzpProductChanges = changes;
    },
    CLEAR_ZZP_PRODUCT: (state) => {
      state.zzpProduct = null;
      state.zzpProductChanges = null;
    },
    SET_NEXT_ZZP_PRODUCT_ID: (state, nextZzpProductId) => {
      state.nextZzpProductId = nextZzpProductId;
    },
  },
  actions: {
    async LOAD_ZZP_PRODUCTS({ commit }) {
      const { data } = await getZzpProductList();

      commit('SET_ZZP_PRODUCTS', data);
    },
    async CREATE_ZZP_PRODUCT(context, zzpProduct) {
      const { data } = await createZzpProduct(zzpProduct);

      return data;
    },
    async EDIT_ZZP_PRODUCT(context, zzpProduct) {
      const { data } = await editZzpProduct(zzpProduct);

      return data;
    },
    async LOAD_ZZP_PRODUCT({ commit }, zzpProductId) {
      const { data } = await getZzpProduct(zzpProductId);

      commit('CLEAR_ZZP_PRODUCT');
      commit('SET_ZZP_PRODUCT', data);

      return data;
    },
    async DELETE_ZZP_PRODUCT(context, zzpProductId) {
      const { data } = await deleteZzpProduct(zzpProductId);

      return data;
    },
    async LOAD_NEXT_ZZP_PRODUCT_ID({ commit }) {
      const { data } = await getNextZzpProductId();

      commit('SET_NEXT_ZZP_PRODUCT_ID', data);
    },
    CLEAR_SESSION_STATE({ commit }) {
      commit('CLEAR_ZZP_PRODUCT', null);
    },
  },
};
