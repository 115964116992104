import { login, logout } from '../../services/backend';

export default {
  state: {
    secureApiUser: null,
  },
  getters: {
    SECURE_API_USER: (state) => state.secureApiUser,
  },
  mutations: {
    SET_SECURE_API_USER: (state, secureApiUser) => {
      state.secureApiUser = secureApiUser;
    },
  },
  actions: {
    /**
     * @return {null}
     */
    async LOGIN({ commit }, { email, password }) {
      const { data } = await login({ email, password });
      commit('SET_SECURE_API_USER', data.data || null);

      return data;
    },
    CLEAR_SESSION_STATE({ commit }) {
      commit('SET_SECURE_API_USER', null);
    },
    async LOGOUT({ commit, getters }) {
      const userRole =
        getters.SECURE_API_USER && getters.SECURE_API_USER.user ? getters.SECURE_API_USER.user.role : null;
      const { data } = await logout(userRole);

      if (data && data.data.logged_out) {
        commit('SET_SECURE_API_USER', null);
      }

      return data;
    },
  },
};
