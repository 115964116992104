import isEqual from 'lodash.isequal';
import {
  getEmailPhoneList,
  getEmailPhone,
  createEmailPhone,
  deleteEmailPhone,
  sendPhoneEmail,
} from '../../services/backend';

export default {
  state: {
    emails_phone: null,
    email_phone: null,
  },
  getters: {
    EMAILS_PHONE: (state) =>
      state.emails_phone && state.emails_phone.data ? state.emails_phone.data : state.emails_phone,
    EMAIL_PHONE: (state) => state.email_phone || {},
  },
  mutations: {
    SET_EMAILS_PHONE: (state, emails_phone) => {
      state.emails_phone = Object.freeze(emails_phone);
    },
    SET_EMAIL_PHONE: (state, email_phone) => {
      if (isEqual(email_phone, state.email_phone)) {
        return;
      }

      state.email_phone = email_phone ? { ...email_phone } : null;
    },
    CLEAR_EMAIL_PHONE: (state) => {
      state.email_phone = null;
    },
  },
  actions: {
    async LOAD_EMAILS_PHONE({ commit }) {
      const { data } = await getEmailPhoneList();

      commit('SET_EMAILS_PHONE', data);
    },
    async CREATE_EMAIL_PHONE(context, email_phone) {
      const { data } = await createEmailPhone(email_phone);

      return data;
    },
    async LOAD_EMAIL_PHONE({ commit }, emailId) {
      const { data } = await getEmailPhone(emailId);

      commit('CLEAR_EMAIL_PHONE');
      commit('SET_EMAIL_PHONE', data);

      return data;
    },
    async DELETE_EMAIL_PHONE(context, emailId) {
      const { data } = await deleteEmailPhone(emailId);

      return data;
    },
    CLEAR_SESSION_STATE({ commit }) {
      commit('CLEAR_EMAIL_PHONE', null);
    },
    async SEND_PHONE_EMAIL(context, email) {
      const { data } = await sendPhoneEmail(email);

      return data;
    },
  },
};
