<template>
  <div class="footer-bottom container-fluid container-fluid--with-max-width">
    <div class="row g-0">
      <div class="col">
        <nav class="footer-bottom__menu nav">
          <router-link
            v-for="page in filteredFooterMenuPages"
            :key="page.id"
            class="footer-bottom__menu-link nav-link"
            :to="page.full_url === 'home' ? `/${currentLanguage}` : `/${currentLanguage}/${page.full_url}` || '/'"
            exact-path
            >{{ page.menu_title }}</router-link
          >
        </nav>
        {{ '©' }} {{ new Date().getFullYear() }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import sortBy from 'lodash.sortby';

export default {
  name: 'FooterBottom',
  computed: {
    ...mapGetters({
      dutchPages: 'PUBLIC_DUTCH_PAGES',
      englishPages: 'PUBLIC_ENGLISH_PAGES',
    }),
    currentLanguage() {
      return this.$route.path.includes('/en/') || this.$route.fullPath === '/en' ? 'en' : 'nl';
    },
    pages() {
      return this.currentLanguage === 'en' ? this.englishPages : this.dutchPages;
    },
    filteredFooterMenuPages() {
      return this.pages && this.pages.length > 0
        ? sortBy(
            this.pages.filter((page) => page.visible_in_footer_menu),
            'position_in_footer_menu'
          )
        : [];
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/functions', '~bootstrap/scss/variables', '~bootstrap/scss/mixins';
@import '../styles/variables';
@import '../styles/mixins';

.footer-bottom {
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  font-size: pxToRem(14px);
  padding: pxToRem(10px) pxToRem(30px);
  text-align: center;

  .col {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  &__menu {
    justify-content: center;
  }

  &__menu-link {
    color: rgba(255, 255, 255, 0.9);
    padding: 0 pxToRem(16px);

    &:hover,
    &.active {
      color: $white;
      text-decoration: underline;
    }
  }
}
</style>
