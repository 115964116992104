export default {
  state: {
    locale: null,
  },
  getters: {
    LOCALE: (state) => state.locale,
  },
  mutations: {
    SET_LOCALE: (state, locale) => {
      state.locale = locale;
    },
  },
};
