import isEqual from 'lodash.isequal';
import {
  getNewsCategoryList,
  getNewsCategory,
  createNewsCategory,
  editNewsCategory,
  deleteNewsCategory,
} from '../../services/backend';

export default {
  state: {
    newsCategories: null,
    newsCategory: null,
    newsCategoryChanges: null,
  },
  getters: {
    NEWS_CATEGORIES: (state) =>
      state.newsCategories && state.newsCategories.data ? state.newsCategories.data : state.newsCategories,
    NEWS_CATEGORY: (state) => ({ ...(state.newsCategory || {}), ...state.newsCategoryChanges }),
  },
  mutations: {
    SET_NEWS_CATEGORIES: (state, newsCategories) => {
      state.newsCategories = Object.freeze(newsCategories);
    },
    SET_NEWS_CATEGORY: (state, newsCategory) => {
      if (isEqual(newsCategory, state.newsCategory)) {
        return;
      }

      state.newsCategory = newsCategory ? { ...newsCategory } : null;
      state.newsCategoryChanges = {};
    },
    SET_NEWS_CATEGORY_CHANGES: (state, changes) => {
      state.newsCategoryChanges = changes;
    },
    CLEAR_NEWS_CATEGORY: (state) => {
      state.newsCategory = null;
      state.newsCategoryChanges = null;
    },
  },
  actions: {
    async LOAD_NEWS_CATEGORIES({ commit }) {
      const { data } = await getNewsCategoryList();

      commit('SET_NEWS_CATEGORIES', data);
    },
    async CREATE_NEWS_CATEGORY(context, newsCategory) {
      const { data } = await createNewsCategory(newsCategory);

      return data;
    },
    async EDIT_NEWS_CATEGORY(context, newsCategory) {
      const { data } = await editNewsCategory(newsCategory);

      return data;
    },
    async LOAD_NEWS_CATEGORY({ commit }, newsCategoryId) {
      const { data } = await getNewsCategory(newsCategoryId);

      commit('CLEAR_NEWS_CATEGORY');
      commit('SET_NEWS_CATEGORY', data);

      return data;
    },
    async DELETE_NEWS_CATEGORY(context, newsCategoryId) {
      const { data } = await deleteNewsCategory(newsCategoryId);

      return data;
    },
    CLEAR_SESSION_STATE({ commit }) {
      commit('CLEAR_NEWS_CATEGORY', null);
    },
  },
};
