<template>
  <language-switch :english-translation-url="englishTranslationUrl" :dutch-translation-url="dutchTranslationUrl" />
  <Header
    :is-preview-page="isPreviewPage"
    :news-category="headerNewsCategory"
    :subtitle="headerSubtitle"
    :title="headerTitle"
    :intro="headerIntro"
    :show-action-btn="headerShowActionBtn"
    :action-btn-title="headerActionBtnTitle"
    :image="headerImage"
    :show-slider="headerShowSlider"
    :slider-images="headerSliderImages"
  />
  <router-view />
  <Footer />
  <modal-list />
  <button id="cookie-consent-btn" class="cookie-consent-btn">
    <img title="Customize settings" src="@/assets/images/cookie/cookie-icon-lochmara.svg" />
  </button>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import * as GTagOptIn from 'gtag-opt-in';
import emitter from './assets/js/emitter';
import WithPageInfo from './components/with-page-info';
import WithMetaInfo from './components/with-meta-info';
import Header from './components/header';
import LanguageSwitch from './components/language-switch';
import Footer from './components/footer';
import ModalList from './components/modal/modal-list';

export default {
  name: 'App',
  mixins: [WithPageInfo, WithMetaInfo],
  components: {
    Header,
    LanguageSwitch,
    Footer,
    ModalList,
  },
  data() {
    return { isPreviewPage: false };
  },
  computed: {
    ...mapGetters({
      currentPublicPage: 'CURRENT_PUBLIC_PAGE',
      previewPage: 'PAGE',
      currentPublicNewsItem: 'CURRENT_PUBLIC_NEWS_ITEM',
      previewNewsItem: 'NEWS_ITEM',
      currentPublicTip: 'CURRENT_PUBLIC_TIP',
      previewTip: 'TIP',
      currentPublicProduct: 'CURRENT_PUBLIC_PRODUCT',
      previewProduct: 'PRODUCT',
    }),
    page() {
      return this.isPreviewPage ? this.previewPage : this.currentPublicPage;
    },
    newsItem() {
      return this.isPreviewPage ? this.previewNewsItem : this.currentPublicNewsItem;
    },
    tip() {
      return this.isPreviewPage ? this.previewTip : this.currentPublicTip;
    },
    product() {
      return this.isPreviewPage ? this.previewProduct : this.currentPublicProduct;
    },
    isCmsPage() {
      return !this.$route.meta.publicAccess;
    },
    currentLanguage() {
      return this.$route.path.includes('/en/') || this.$route.fullPath === '/en' ? 'en' : 'nl';
    },
    headerNewsCategory() {
      return (!this.isCmsPage || this.isPreviewPage) && this.newsItem && this.newsItem.category
        ? this.newsItem.category
        : this.getCmsPageHeaderInfo().news_category;
    },
    headerTitle() {
      return (!this.isCmsPage || this.isPreviewPage) && this.page && this.page.title
        ? this.page.title
        : (!this.isCmsPage || this.isPreviewPage) && this.newsItem && this.newsItem.title
        ? this.newsItem.title
        : (!this.isCmsPage || this.isPreviewPage) && this.tip && this.tip.title
        ? this.tip.title
        : (!this.isCmsPage || this.isPreviewPage) && this.product && this.product.title
        ? this.product.title
        : this.getCmsPageHeaderInfo().title;
    },
    headerSubtitle() {
      return (!this.isCmsPage || this.isPreviewPage) && this.page && this.page.show_subtitle && this.page.subtitle
        ? this.page.subtitle
        : (!this.isCmsPage || this.isPreviewPage) &&
          this.newsItem &&
          this.newsItem.show_subtitle &&
          this.newsItem.subtitle
        ? this.newsItem.subtitle
        : (!this.isCmsPage || this.isPreviewPage) && this.tip && this.tip.show_subtitle && this.tip.subtitle
        ? this.tip.subtitle
        : (!this.isCmsPage || this.isPreviewPage) && this.product && this.product.show_subtitle && this.product.subtitle
        ? this.product.subtitle
        : this.getCmsPageHeaderInfo().subtitle;
    },
    headerIntro() {
      return (!this.isCmsPage || this.isPreviewPage) && this.page && this.page.show_intro && this.page.intro
        ? this.page.intro
        : (!this.isCmsPage || this.isPreviewPage) && this.newsItem && this.newsItem.show_intro && this.newsItem.intro
        ? this.newsItem.intro
        : (!this.isCmsPage || this.isPreviewPage) && this.tip && this.tip.show_intro && this.tip.intro
        ? this.tip.intro
        : (!this.isCmsPage || this.isPreviewPage) && this.product && this.product.show_intro && this.product.intro
        ? this.product.intro
        : this.getCmsPageHeaderInfo().intro;
    },
    headerShowActionBtn() {
      return (!this.isCmsPage || this.isPreviewPage) && this.page
        ? this.page.show_action_btn
        : (!this.isCmsPage || this.isPreviewPage) && this.newsItem
        ? this.newsItem.show_action_btn
        : (!this.isCmsPage || this.isPreviewPage) && this.tip
        ? this.tip.show_action_btn
        : (!this.isCmsPage || this.isPreviewPage) && this.product
        ? this.product.show_action_btn
        : this.getCmsPageHeaderInfo().show_action_btn;
    },
    headerActionBtnTitle() {
      return (!this.isCmsPage || this.isPreviewPage) && this.page && this.page.show_action_btn
        ? this.page.action_btn_title
        : (!this.isCmsPage || this.isPreviewPage) && this.newsItem && this.newsItem.show_action_btn
        ? this.newsItem.action_btn_title
        : (!this.isCmsPage || this.isPreviewPage) && this.tip && this.tip.show_action_btn
        ? this.tip.action_btn_title
        : (!this.isCmsPage || this.isPreviewPage) && this.product && this.product.show_action_btn
        ? this.product.action_btn_title
        : this.getCmsPageHeaderInfo().action_btn_title;
    },
    headerImage() {
      return (!this.isCmsPage || this.isPreviewPage) && this.page && this.page.image
        ? this.page.image
        : (!this.isCmsPage || this.isPreviewPage) && this.newsItem && this.newsItem.image
        ? this.newsItem.image
        : (!this.isCmsPage || this.isPreviewPage) && this.tip && this.tip.image
        ? this.tip.image
        : (!this.isCmsPage || this.isPreviewPage) && this.product && this.product.image
        ? this.product.image
        : this.getCmsPageHeaderInfo().image;
    },
    headerShowSlider() {
      return (!this.isCmsPage || this.isPreviewPage) && this.page
        ? this.page.show_slider
        : (!this.isCmsPage && this.newsItem) || this.tip || this.product
        ? false
        : this.getCmsPageHeaderInfo().show_slider;
    },
    headerSliderImages() {
      return (!this.isCmsPage || this.isPreviewPage) && this.page && this.page.show_slider && this.page.slider_images
        ? this.page.slider_images
        : (!this.isCmsPage && this.newsItem) || this.tip || this.product
        ? null
        : this.getCmsPageHeaderInfo().slider_images;
    },
    englishTranslationUrl() {
      let englishTranslationUrl = '/en';

      if (this.currentLanguage === 'en') {
        englishTranslationUrl = this.$route.path;
      }

      if (this.currentLanguage === 'nl') {
        if (this.$route.name === 'newsDetail' && this.newsItem) {
          englishTranslationUrl = this.newsItem.news_translation
            ? `/${this.newsItem.news_translation.language}/news/${this.newsItem.news_translation.url}`
            : englishTranslationUrl;
        } else if (this.$route.name === 'tipDetail' && this.tip) {
          englishTranslationUrl = this.tip.tip_translation
            ? `/${this.tip.tip_translation.language}/tips-and-tricks/${this.tip.tip_translation.url}`
            : englishTranslationUrl;
        } else if (this.$route.name === 'productDetail' && this.product) {
          englishTranslationUrl = this.product.product_translation
            ? `/${this.product.product_translation.language}/sale/product-sale/${this.product.product_translation.url}`
            : englishTranslationUrl;
        } else if (this.$route.name === 'contactPage' && this.page) {
          englishTranslationUrl = `/${this.page.page_translation.language}/${this.page.page_translation.url}`;
        } else if (
          (this.$route.name === 'page' ||
            this.$route.name === 'subPage' ||
            this.$route.name === 'newsOverview' ||
            this.$route.name === 'tipOverview') &&
          this.page
        ) {
          englishTranslationUrl =
            this.page.page_translation && this.page.page_translation.full_url !== 'home-en'
              ? `/${this.page.page_translation.language}/${this.page.page_translation.full_url}`
              : englishTranslationUrl;
        }
      }

      return englishTranslationUrl;
    },
    dutchTranslationUrl() {
      let dutchTranslationUrl = '/nl';

      if (this.currentLanguage === 'nl') {
        dutchTranslationUrl = this.$route.path;
      }

      if (this.currentLanguage === 'en') {
        if (this.$route.name === 'newsDetail' && this.newsItem) {
          dutchTranslationUrl = this.newsItem.news_translation
            ? `/${this.newsItem.news_translation.language}/nieuws/${this.newsItem.news_translation.url}`
            : dutchTranslationUrl;
        } else if (this.$route.name === 'tipDetail' && this.tip) {
          dutchTranslationUrl = this.tip.tip_translation
            ? `/${this.tip.tip_translation.language}/tips-en-tricks/${this.tip.tip_translation.url}`
            : dutchTranslationUrl;
        } else if (this.$route.name === 'productDetail' && this.product) {
          dutchTranslationUrl = this.product.product_translation
            ? `/${this.product.product_translation.language}/verkoop/product-verkoop/${this.product.product_translation.url}`
            : dutchTranslationUrl;
        } else if (this.$route.name === 'contactPage' && this.page) {
          dutchTranslationUrl = `/${this.page.page_translation.language}/${this.page.page_translation.url}`;
        } else if (
          (this.$route.name === 'page' ||
            this.$route.name === 'subPage' ||
            this.$route.name === 'newsOverview' ||
            this.$route.name === 'tipOverview') &&
          this.page
        ) {
          dutchTranslationUrl =
            this.page.page_translation && this.page.page_translation.full_url !== 'home-nl'
              ? `/${this.page.page_translation.language}/${this.page.page_translation.full_url}`
              : dutchTranslationUrl;
        }
      }

      return dutchTranslationUrl;
    },
  },
  mounted() {
    if (this.$cookie.isCookieAvailable('cc_cookie')) {
      this.$store.dispatch('SET_CONSENTED_COOKIE_CATEGORIES', this.$cookie.getCookie('cc_cookie').categories);
    }
    if (!this.$store.getters.CONSENTED_COOKIE_CATEGORIES.some((cat) => cat === 'statistic')) {
      this.$cookie.removeCookie('__atuvc');
      this.$cookie.removeCookie('__atuvs');
    }
    GTagOptIn.register('G-VEVHKHT125');
    emitter.on('cookie-change', this.cookieChange);
  },
  updated() {
    this.setMetaData();
  },
  unmounted() {
    emitter.all.clear();
  },
  methods: {
    ...mapActions({
      loadPageByUrl: 'LOAD_PUBLIC_PAGE_BY_URL',
      loadNewsByUrl: 'LOAD_PUBLIC_NEWS_ITEM_BY_URL',
      loadTipByUrl: 'LOAD_PUBLIC_TIP_BY_URL',
      loadProductByUrl: 'LOAD_PUBLIC_PRODUCT_BY_URL',
    }),
    cookieChange(cookie) {
      if (cookie?.categories === 'statisitcs') {
        GTagOptIn.optIn();
      } else {
        GTagOptIn.optOut();
      }
      this.$store.dispatch('SET_COOKIE_SETTINGS', cookie?.categories);
      this.$router.go();
    },
    setMetaData() {
      const currentUrl = window.location.href;
      let description = this.$t('defaultMetaDescription');
      let image = null;

      if (this.pageType) {
        if (this[this.pageType]?.seo_description) {
          description = this[this.pageType].seo_description;
        }
        if (this[this.pageType]?.facebook_image) {
          image = `https://www.profscomputers.nl/api/public/uploads/images/${this[this.pageType].facebook_image}`;
        }
      }

      document.querySelector('meta[property="og:title"]').setAttribute('content', this.metaTitle);
      document.querySelector('meta[name="twitter:title"]').setAttribute('content', this.metaTitle);
      document.querySelector('meta[name="description"]').setAttribute('content', description);
      document.querySelector('meta[name="twitter:description"]').setAttribute('content', description);
      document.querySelector('meta[property="og:url"]').setAttribute('content', currentUrl);
      document.querySelector('meta[name="twitter:url"]').setAttribute('content', currentUrl);
      document.querySelector('meta[property="og:image"]').setAttribute('content', image);
      document.querySelector('meta[name="twitter:image"]').setAttribute('content', image);
    },
  },
  watch: {
    $route(to, from) {
      if (
        (to.name === 'previewPage' ||
          to.name === 'previewNews' ||
          to.name === 'previewTip' ||
          to.name === 'previewProduct') &&
        to.params.id
      ) {
        this.isPreviewPage = true;
      } else {
        this.isPreviewPage = false;
      }

      if ((to.name === 'page' && from.name === 'page') || (to.name === 'subPage' && from.name === 'subPage')) {
        const fullPath =
          !to.fullPath || to.fullPath === '' || to.fullPath === '/' || to.fullPath === '/nl' || to.fullPath === '/nl/'
            ? '/home-nl'
            : to.fullPath === '/en' || to.fullPath === '/en/'
            ? '/home-en'
            : to.fullPath;
        const fullPathWithoutLanguage =
          fullPath.includes('/en/') || fullPath.includes('/nl/') ? fullPath.slice(3) : fullPath;
        const fullPathWithoutLeadingSlash = fullPathWithoutLanguage.replace(/^\/+/g, '');
        this.loadPageByUrl({ url: fullPathWithoutLeadingSlash, currentLanguage: this.currentLanguage });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import './styles/variables';

.cookie-consent-btn {
  background: $white;
  border: none;
  border-radius: 50%;
  bottom: 10px;
  height: 40px;
  left: 10px;
  outline: 0;
  padding: 0;
  position: fixed;
  transition: transform 0.1s linear 0s;
  width: 40px;
  z-index: 9999;

  &:hover {
    transform: scale(1.1);
  }

  img {
    width: 30px;
  }
}
</style>
