<template>
  <div :class="{ 'opening-hours': true, 'opening-hours--footer': inFooter }">
    <div :class="{ 'opening-hours__col': true, 'opening-hours__col--footer': inFooter }">
      <div class="opening-hours__days">
        {{ $t('Monday') }}<br />
        {{ $t('Tuesday') }}<br />
        {{ $t('Wednesday') }}<br />
        {{ $t('Thursday') }}<br />
      </div>
      <div class="opening-hours__hours">
        {{ $t('9:00 till 20:00') }}<br />
        {{ $t('9:00 till 20:00') }}<br />
        {{ $t('9:00 till 20:00') }}<br />
        {{ $t('9:00 till 20:00') }}<br />
      </div>
    </div>
    <div :class="{ 'opening-hours__col': true, 'opening-hours__col--footer': inFooter }">
      <div class="opening-hours__days">
        {{ $t('Friday') }}<br />
        {{ $t('Saturday') }}<br />
        {{ $t('Sunday') }}<br />
      </div>
      <div class="opening-hours__hours">
        {{ $t('9:00 till 20:00') }}<br />
        {{ $t('9:00 till 20:00') }}<br />
        {{ $t('Closed') }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OpeningHours',
  props: {
    inFooter: { type: Boolean, default: false },
  },
};
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/functions', '~bootstrap/scss/variables', '~bootstrap/scss/mixins';
@import '../styles/variables';
@import '../styles/mixins';

.opening-hours {
  flex-direction: column;

  @include media-breakpoint-up(sm) {
    flex-direction: row;
  }

  @include media-breakpoint-up(md) {
    flex-direction: column;
  }

  &--footer {
    @include media-breakpoint-up(xl) {
      flex-direction: row;
    }
  }

  &,
  &__col {
    display: flex;
  }

  &__col {
    &:first-child {
      margin-right: 0;

      @include media-breakpoint-up(sm) {
        margin-right: pxToRem(50px);
      }

      @include media-breakpoint-up(md) {
        margin-right: 0;
      }
    }
  }

  &__col--footer {
    &:first-child {
      @include media-breakpoint-up(xl) {
        margin-right: pxToRem(50px);
      }
    }
  }

  &__days {
    margin-right: pxToRem(30px);
    min-width: pxToRem(80px);
  }
}
</style>
