import isEqual from 'lodash.isequal';
import {
  getNewsGroupList,
  getNewsGroup,
  createNewsGroup,
  editNewsGroup,
  deleteNewsGroup,
} from '../../services/backend';

export default {
  state: {
    newsGroups: null,
    newsGroup: null,
    newsGroupChanges: null,
  },
  getters: {
    NEWS_GROUPS: (state) => (state.newsGroups && state.newsGroups.data ? state.newsGroups.data : state.newsGroups),
    NEWS_GROUP: (state) => ({ ...(state.newsGroup || {}), ...state.newsGroupChanges }),
  },
  mutations: {
    SET_NEWS_GROUPS: (state, newsGroups) => {
      state.newsGroups = Object.freeze(newsGroups);
    },
    SET_NEWS_GROUP: (state, newsGroup) => {
      if (isEqual(newsGroup, state.newsGroup)) {
        return;
      }

      state.newsGroup = newsGroup ? { ...newsGroup } : null;
      state.newsGroupChanges = {};
    },
    SET_NEWS_GROUP_CHANGES: (state, changes) => {
      state.newsGroupChanges = changes;
    },
    CLEAR_NEWS_GROUP: (state) => {
      state.newsGroup = null;
      state.newsGroupChanges = null;
    },
  },
  actions: {
    async LOAD_NEWS_GROUPS({ commit }) {
      const { data } = await getNewsGroupList();

      commit('SET_NEWS_GROUPS', data);
    },
    async CREATE_NEWS_GROUP(context, newsGroup) {
      const { data } = await createNewsGroup(newsGroup);

      return data;
    },
    async EDIT_NEWS_GROUP(context, newsGroup) {
      const { data } = await editNewsGroup(newsGroup);

      return data;
    },
    async LOAD_NEWS_GROUP({ commit }, newsGroupId) {
      const { data } = await getNewsGroup(newsGroupId);

      commit('CLEAR_NEWS_GROUP');
      commit('SET_NEWS_GROUP', data);

      return data;
    },
    async DELETE_NEWS_GROUP(context, newsGroupId) {
      const { data } = await deleteNewsGroup(newsGroupId);

      return data;
    },
    CLEAR_SESSION_STATE({ commit }) {
      commit('CLEAR_NEWS_GROUP', null);
    },
  },
};
