export default {
  computed: {
    pageType() {
      let pageType = null;

      if (this.$route.name && this.$route.name === 'newsDetail' && this.newsItem) {
        pageType = 'newsItem';
      } else if (this.$route.name && this.$route.name === 'tipDetail' && this.tip) {
        pageType = 'tip';
      } else if (this.$route.name && this.$route.name === 'productDetail' && this.product) {
        pageType = 'product';
      } else if (
        this.$route.name &&
        (this.$route.name === 'page' ||
          this.$route.name === 'subPage' ||
          this.$route.name === 'newsOverview' ||
          this.$route.name === 'productOverview' ||
          this.$route.name === 'tipOverview' ||
          this.$route.name === 'contactPage') &&
        this.page
      ) {
        pageType = 'page';
      }

      return pageType;
    },
    metaTitle() {
      const title = 'Profs Computers';
      let metaTitle = title;

      if (this.pageType) {
        metaTitle = `${this[this.pageType].title} | ${title}`;
      }

      return metaTitle;
    },
    metaInfo() {
      const currentUrl = window.location.href;

      return this.pageType
        ? {
            ...(this.metaTitle && { title: this.metaTitle }),
            ...(this.pageType && {
              link: { rel: 'canonical', href: this[this.pageType].canonical_url || currentUrl },
            }),
          }
        : {};
    },
  },
};
