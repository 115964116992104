<template>
  <div class="input-text">
    <fieldset :id="`${id}-group`" class="form-group" :class="{ 'has-error': errorMessage }">
      <div class="input-wrapper" :class="{ 'is-focused': inputIsFocused }">
        <label
          :class="{ active: inputIsFocused || inputValue || (type === 'number' && inputValue === 0) }"
          @click="focusInput"
          >{{ label }}</label
        >

        <input
          class="form-control"
          :class="{ 'is-invalid': rules && errorMessage }"
          :id="id"
          :name="name"
          :ref="id"
          :type="type"
          :value="inputValue"
          @focus="inputIsFocused = true"
          @blur="inputIsFocused = false"
          v-on="validationListeners"
        />
        <slot name="append"></slot>
      </div>

      <div v-if="rules" class="invalid-feedback" :id="`${id}-feedback`" :name="label">{{ errorMessage }}</div>
    </fieldset>
  </div>
</template>

<script>
import { computed } from 'vue';
import { useField } from 'vee-validate';

export default {
  name: 'input-text',
  data() {
    return {
      inputIsFocused: false,
    };
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: 'text',
    },
    label: {
      type: String,
      required: true,
    },
    value: {
      type: [String, Number],
      default: '',
    },
    rules: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const {
      value: inputValue,
      errorMessage,
      handleChange,
      meta,
    } = useField(props.name, props.rules, {
      initialValue: props.value,
      label: props.label,
      validateOnValueUpdate: false,
    });

    const validationListeners = computed(() => {
      if (!errorMessage.value) {
        return {
          blur: handleChange,
          change: handleChange,
          input: (e) => handleChange(e, false),
        };
      }

      return {
        blur: handleChange,
        change: handleChange,
        input: handleChange,
      };
    });

    return {
      errorMessage,
      inputValue,
      meta,
      validationListeners,
    };
  },
  methods: {
    focusInput() {
      this.$refs[this.id].focus();
    },
  },
};
</script>
