import { addListener as addApiListener } from './api';
import { isServerError } from './backend';
import handleServerError from './handle-server-error';

export default ({ store }) => {
  addApiListener(async (promise) => {
    promise.catch(async (err) => {
      if (!isServerError(err)) {
        return;
      }

      const isLoggedInAsAdmin = store.state.secureApiUser;

      if (!isLoggedInAsAdmin) {
        await store.dispatch('CLEAR_SESSION_STATE');
      }

      handleServerError(err);
    });
  });
};
