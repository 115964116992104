<template>
  <footer class="footer">
    <waves-border flip-horizontally />
    <div class="footer__top container-fluid container-fluid--with-max-width p-0">
      <div class="row g-0">
        <div class="footer__cta col-xs-12 col-md-4">
          <img class="footer__logo img-fluid mb-4" src="/images/logo-inverted.svg" />
          <p class="mb-4">
            {{ $t('Footer intro') }}
          </p>
          <contact-and-social-buttons class="mb-4" />
        </div>
        <div class="footer__opening-hours col-xs-12 col-md-4 col-lg-5 d-flex flex-column">
          <div>
            <h2>{{ $t('Opening hours') }}</h2>

            <opening-hours :in-footer="true" />
          </div>
        </div>
        <div class="footer__contact-info col-xs-12 col-md-4 col-lg-3 d-flex flex-column">
          <div>
            <h2>{{ $t('Contact information') }}</h2>

            <contact-info />
          </div>
        </div>
      </div>
    </div>
    <footer-bottom />
  </footer>
</template>

<script>
import WavesBorder from './waves-border';
import ContactAndSocialButtons from './contact-and-social-buttons';
import OpeningHours from './opening-hours';
import ContactInfo from './contact-info';
import FooterBottom from './footer-bottom';

export default {
  name: 'Footer',
  components: {
    WavesBorder,
    ContactAndSocialButtons,
    OpeningHours,
    ContactInfo,
    FooterBottom,
  },
};
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/functions', '~bootstrap/scss/variables', '~bootstrap/scss/mixins';
@import '../styles/variables';
@import '../styles/mixins';

.footer {
  background-color: $cerulean;
  background: linear-gradient(180deg, $cerulean 0%, $lochmara 60%, $bahama-blue 75%, $tiber 100%);
  color: rgba(255, 255, 255, 0.9);
  padding: 0;
  position: relative;
  z-index: 1;

  &__logo {
    max-width: pxToRem(405px);
  }

  &__cta {
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: pxToRem(30px) pxToRem(30px) 0;
    text-align: center;

    @include media-breakpoint-up(md) {
      display: block;
      padding: pxToRem(30px) pxToRem(20px) pxToRem(20px) pxToRem(30px);
      text-align: left;
    }

    .action {
      box-shadow: 0 pxToRem(2px) pxToRem(10px) 0 rgba(0, 0, 0, 0.01), 0 pxToRem(5px) pxToRem(15px) 0 rgba(0, 0, 0, 0.01);
      padding: pxToRem(12px) pxToRem(20px);
    }
  }

  &__opening-hours {
    align-items: center;
    padding: 0 pxToRem(30px) 0;

    @include media-breakpoint-down(md) {
      margin-bottom: pxToRem(30px);
    }

    @include media-breakpoint-up(md) {
      padding: pxToRem(30px) pxToRem(20px) pxToRem(20px);
    }
  }

  &__contact-info {
    padding: 0 pxToRem(30px) pxToRem(20px);

    @include media-breakpoint-up(md) {
      padding: pxToRem(30px) 0 pxToRem(20px) pxToRem(20px);
    }
  }

  h2 {
    color: $white;
    font-size: pxToRem(18px);
    letter-spacing: pxToRem(1px);
    margin-bottom: pxToRem(20px);
    text-align: center;

    @include media-breakpoint-up(md) {
      text-align: left;
    }

    @include media-breakpoint-up(lg) {
      margin-bottom: pxToRem(30px);
    }
  }
}
</style>
