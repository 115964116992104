import isEqual from 'lodash.isequal';
import {
  getServiceGroupList,
  getServiceGroup,
  createServiceGroup,
  editServiceGroup,
  deleteServiceGroup,
} from '../../services/backend';

export default {
  state: {
    serviceGroups: null,
    serviceGroup: null,
    serviceGroupChanges: null,
  },
  getters: {
    SERVICE_GROUPS: (state) =>
      state.serviceGroups && state.serviceGroups.data ? state.serviceGroups.data : state.serviceGroups,
    SERVICE_GROUP: (state) => ({ ...(state.serviceGroup || {}), ...state.serviceGroupChanges }),
  },
  mutations: {
    SET_SERVICE_GROUPS: (state, serviceGroups) => {
      state.serviceGroups = Object.freeze(serviceGroups);
    },
    SET_SERVICE_GROUP: (state, serviceGroup) => {
      if (isEqual(serviceGroup, state.serviceGroup)) {
        return;
      }

      state.serviceGroup = serviceGroup ? { ...serviceGroup } : null;
      state.serviceGroupChanges = {};
    },
    SET_SERVICE_GROUP_CHANGES: (state, changes) => {
      state.serviceGroupChanges = changes;
    },
    CLEAR_SERVICE_GROUP: (state) => {
      state.serviceGroup = null;
      state.serviceGroupChanges = null;
    },
  },
  actions: {
    async LOAD_SERVICE_GROUPS({ commit }) {
      const { data } = await getServiceGroupList();

      commit('SET_SERVICE_GROUPS', data);
    },
    async CREATE_SERVICE_GROUP(context, serviceGroup) {
      const { data } = await createServiceGroup(serviceGroup);

      return data;
    },
    async EDIT_SERVICE_GROUP(context, serviceGroup) {
      const { data } = await editServiceGroup(serviceGroup);

      return data;
    },
    async LOAD_SERVICE_GROUP({ commit }, serviceGroupId) {
      const { data } = await getServiceGroup(serviceGroupId);

      commit('CLEAR_SERVICE_GROUP');
      commit('SET_SERVICE_GROUP', data);

      return data;
    },
    async DELETE_SERVICE_GROUP(context, serviceGroupId) {
      const { data } = await deleteServiceGroup(serviceGroupId);

      return data;
    },
    CLEAR_SESSION_STATE({ commit }) {
      commit('CLEAR_SERVICE_GROUP', null);
    },
  },
};
