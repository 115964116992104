<template>
  <div class="input-textarea">
    <fieldset :id="`${id}-group`" class="form-group" :class="{ 'has-error': errorMessage }">
      <div class="input-wrapper" :class="{ 'is-focused': inputIsFocused }">
        <label
          :class="{ active: isEditor || inputIsFocused || inputValue, 'active--tinymce': isEditor, 'mb-5': isEditor }"
          @click="focusInput"
          >{{ label }}</label
        >

        <Editor
          v-if="isEditor"
          :id="`${id}-editor`"
          :name="name"
          :ref="id"
          @focus="inputIsFocused = true"
          @blur="inputIsFocused = false"
          @input="handleInput($event)"
          :state="rules ? !!errorMessage : null"
          :value="inputValue"
          :initial-value="inputValue"
          v-model="inputValue"
          api-key="2bwkylojh63sw6gayqsn78edvgp59bccannd0s66t1po6ilp"
          :init="tinyMceInit"
        />

        <textarea
          v-else
          class="form-control"
          :class="{ 'is-invalid': rules && errorMessage }"
          :id="id"
          :name="name"
          :ref="id"
          rows="3"
          :value="inputValue"
          :maxLength="maxLength"
          @focus="inputIsFocused = true"
          @blur="inputIsFocused = false"
          v-on="validationListeners"
        />
      </div>

      <div class="input-textarea__feedback d-flex justify-content-end">
        <div v-if="rules" class="invalid-feedback" :id="`${id}-feedback`" :name="label">
          {{ errorMessage }}
        </div>
        <div v-if="maxLength" class="character-count" :id="`${id}-character-count`">
          {{ characterCount }} / {{ maxLength }}
        </div>
      </div>
    </fieldset>
  </div>
</template>

<script>
import axios from 'axios';
import Editor from '@tinymce/tinymce-vue';
import { computed } from 'vue';
import { useField, useFieldValue } from 'vee-validate';

const axiosOptions = {
  // baseURL: 'http://localhost:8000/api/',
  baseURL: 'https://www.profscomputers.nl/api/',
  withCredentials: true,
  timeout: 15000,
};

const apiAxios = axios.create(axiosOptions);
const getCSRFToken = () => apiAxios.get('csrf-cookie');

export default {
  name: 'input-textarea',
  components: {
    Editor,
  },
  data() {
    return {
      inputIsFocused: false,
      tinyMceInit: {
        content_css: [
          '/css-styling/bootstrap-grid.min.css',
          '/css-styling/bootstrap.min.css',
          '/css-styling/cms-editor.css',
        ],
        height: 500,
        menubar: true,
        plugins:
          'anchor autolink charmap codesample emoticons image link lists advlist media searchreplace table visualblocks wordcount preview code fullscreen insertdatetime help',
        toolbar:
          'undo redo | styles | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | link image | help',
        automatic_uploads: true,
        image_advtab: true,
        image_class_list: [{ title: 'Responsive', value: 'img-responsive' }],
        image_upload_url: '/api/image-upload/tiny-mce-upload',
        images_upload_base_path: 'https://www.profscomputers.nl',
        // eslint-disable-next-line func-names
        images_upload_handler: (blobInfo, progress) =>
          new Promise((resolve, reject) => {
            this.$store.dispatch('START_IMAGE_LOADING');
            const formData = new FormData();
            formData.append('file', blobInfo.blob(), blobInfo.filename());
            formData.append('page-type', this.pageType);
            formData.append('page-id', this.pageId);

            // related to aborting the request
            const CancelToken = this.axios.CancelToken;
            const source = CancelToken.source();
            getCSRFToken().then(() =>
              this.axios({
                // baseURL: 'http://localhost:8000/api/',
                baseURL: 'https://www.profscomputers.nl/api/',
                withCredentials: true,
                timeout: 15000,
                method: 'post',
                url: 'image-upload/tiny-mce-upload',
                data: formData,
                cancelToken: source.token,
                onUploadProgress: (e) => {
                  // updating progress indicator
                  progress(e.lengthComputable, e.loaded, e.total);
                },
              })
                .then((response) => {
                  this.$store.dispatch('STOP_IMAGE_LOADING');
                  resolve(response.data.data);
                })
                .catch((requestError) => {
                  this.$store.dispatch('STOP_IMAGE_LOADING');
                  if (this.axios.isCancel(requestError)) {
                    // eslint-disable-next-line no-console
                    console.log('Request canceled', requestError.message);
                  } else {
                    reject(requestError.response.data.title);
                  }
                })
            );
          }),
      },
    };
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      default: null,
    },
    rules: {
      type: String,
      default: '',
    },
    isEditor: {
      type: Boolean,
      default: false,
    },
    pageType: String,
    pageId: {
      type: [Number, String],
    },
    maxLength: Number,
  },
  setup(props) {
    const currentTextareaValue = useFieldValue(props.name);
    const {
      value: inputValue,
      errorMessage,
      handleChange,
      meta,
    } = useField(props.name, props.rules, {
      initialValue: props.value,
      label: props.label,
      validateOnValueUpdate: false,
    });

    const validationListeners = computed(() => {
      if (!errorMessage.value) {
        return {
          blur: handleChange,
          change: handleChange,
          input: (e) => handleChange(e, false),
        };
      }

      return {
        blur: handleChange,
        change: handleChange,
        input: handleChange,
      };
    });

    const characterCount = computed(() =>
      props.maxLength && currentTextareaValue?.value ? currentTextareaValue.value.length : 0
    );

    return {
      handleChange,
      errorMessage,
      inputValue,
      meta,
      validationListeners,
      characterCount,
    };
  },
  methods: {
    handleInput(event) {
      const content = event.target.innerHTML;
      this.$refs[this.id] = content;
    },
    focusInput() {
      this.$refs[this.id].focus();
    },
  },
};
</script>

<style scoped lang="scss">
.input-textarea {
  &__feedback {
    .invalid-feedback {
      flex: 1;
    }
  }
}
</style>
