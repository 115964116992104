<template>
  <Modal :close="closeModal">
    <div class="contact-modal modal-dialog modal-md">
      <div class="modal-content shadow-lg">
        <header class="modal-header">
          <h5 class="modal-title">{{ title }}</h5>
          <button type="button" aria-label="Close" class="btn-close" @click="closeModal"></button>
        </header>
        <div class="modal-body">
          <contact-form @mailIsSend="ok" />
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import ContactForm from '../contact-form';

export default {
  name: 'ContactModal',
  components: {
    ContactForm,
  },
  data() {},
  props: {
    title: String,
  },
  methods: {
    ok() {
      this.$emit('resolve');
    },
    closeModal() {
      this.$emit('resolve', 'close-modal');
    },
  },
};
</script>

<style lang="scss">
.contact-modal {
  .contact-form {
    &__submit-btn {
      align-self: center;
    }

    .input-text:first-child {
      .form-group {
        margin-top: 0;
      }
    }
  }
}
</style>
