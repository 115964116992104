import messagesNL from './nl';
import messagesEN from './en';

export const vueI18nMessages = {
  en: messagesEN,
  nl: messagesNL,
};

export default {
  en: {
    name: 'English',
    load: () => {
      return import('./en');
    },
  },
  nl: {
    name: 'Nederlands',
    load: () => {
      return import('./nl');
    },
  },
};
