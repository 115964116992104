import isEqual from 'lodash.isequal';
import {
  getFeatureList,
  getFeature,
  createFeature,
  editFeature,
  deleteFeature,
  getPublicFeatureList,
} from '../../services/backend';

export default {
  state: {
    features: null,
    publicFeatures: null,
    feature: null,
    featureChanges: null,
  },
  getters: {
    FEATURES: (state) => (state.features && state.features.data ? state.features.data : state.features),
    PUBLIC_FEATURES: (state) =>
      state.publicFeatures && state.publicFeatures.data ? state.publicFeatures.data : state.publicFeatures,
    FEATURE: (state) => ({ ...(state.feature || {}), ...state.featureChanges }),
  },
  mutations: {
    SET_FEATURES: (state, features) => {
      state.features = Object.freeze(features);
    },
    SET_PUBLIC_FEATURES: (state, publicFeatures) => {
      state.publicFeatures = Object.freeze(publicFeatures);
    },
    SET_FEATURE: (state, feature) => {
      if (isEqual(feature, state.feature)) {
        return;
      }

      state.feature = feature ? { ...feature } : null;
      state.featureChanges = {};
    },
    SET_FEATURE_CHANGES: (state, changes) => {
      state.featureChanges = changes;
    },
    CLEAR_FEATURE: (state) => {
      state.feature = null;
      state.featureChanges = null;
    },
  },
  actions: {
    async LOAD_FEATURES({ commit }) {
      const { data } = await getFeatureList();

      commit('SET_FEATURES', data);
    },
    async LOAD_PUBLIC_FEATURES({ commit }) {
      const { data } = await getPublicFeatureList();

      commit('SET_PUBLIC_FEATURES', data);
    },
    async CREATE_FEATURE(context, feature) {
      const { data } = await createFeature(feature);

      return data;
    },
    async EDIT_FEATURE(context, feature) {
      const { data } = await editFeature(feature);

      return data;
    },
    async LOAD_FEATURE({ commit }, featureId) {
      const { data } = await getFeature(featureId);

      commit('CLEAR_FEATURE');
      commit('SET_FEATURE', data);

      return data;
    },
    async DELETE_FEATURE(context, featureId) {
      const { data } = await deleteFeature(featureId);

      return data;
    },
    CLEAR_SESSION_STATE({ commit }) {
      commit('CLEAR_FEATURE', null);
    },
  },
};
