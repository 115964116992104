import isEqual from 'lodash.isequal';
import {
  getEmailRemoteAssistanceList,
  getEmailRemoteAssistance,
  createEmailRemoteAssistance,
  deleteEmailRemoteAssistance,
  sendRemoteAssistanceEmail,
} from '../../services/backend';

export default {
  state: {
    emails_remote_assistance: null,
    email_remote_assistance: null,
  },
  getters: {
    EMAILS_REMOTE_ASSISTANCE: (state) =>
      state.emails_remote_assistance && state.emails_remote_assistance.data
        ? state.emails_remote_assistance.data
        : state.emails_remote_assistance,
    EMAIL_REMOTE_ASSISTANCE: (state) => state.email_remote_assistance || {},
  },
  mutations: {
    SET_EMAILS_REMOTE_ASSISTANCE: (state, emails_remote_assistance) => {
      state.emails_remote_assistance = Object.freeze(emails_remote_assistance);
    },
    SET_EMAIL_REMOTE_ASSISTANCE: (state, email_remote_assistance) => {
      if (isEqual(email_remote_assistance, state.email_remote_assistance)) {
        return;
      }

      state.email_remote_assistance = email_remote_assistance ? { ...email_remote_assistance } : null;
    },
    CLEAR_EMAIL_REMOTE_ASSISTANCE: (state) => {
      state.email_remote_assistance = null;
    },
  },
  actions: {
    async LOAD_EMAILS_REMOTE_ASSISTANCE({ commit }) {
      const { data } = await getEmailRemoteAssistanceList();

      commit('SET_EMAILS_REMOTE_ASSISTANCE', data);
    },
    async CREATE_EMAIL_REMOTE_ASSISTANCE(context, email_remote_assistance) {
      const { data } = await createEmailRemoteAssistance(email_remote_assistance);

      return data;
    },
    async LOAD_EMAIL_REMOTE_ASSISTANCE({ commit }, emailId) {
      const { data } = await getEmailRemoteAssistance(emailId);

      commit('CLEAR_EMAIL_REMOTE_ASSISTANCE');
      commit('SET_EMAIL_REMOTE_ASSISTANCE', data);

      return data;
    },
    async DELETE_EMAIL_REMOTE_ASSISTANCE(context, emailId) {
      const { data } = await deleteEmailRemoteAssistance(emailId);

      return data;
    },
    CLEAR_SESSION_STATE({ commit }) {
      commit('CLEAR_EMAIL_REMOTE_ASSISTANCE', null);
    },
    async SEND_REMOTE_ASSISTANCE_EMAIL(context, email) {
      const { data } = await sendRemoteAssistanceEmail(email);

      return data;
    },
  },
};
